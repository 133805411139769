
/*
  Media drag drop
*/
.file-drag-drop {
    height: 140px;
    background: $white;
    margin-top: 30px;

    &:first-child {
        margin-top: 0;
    }
}
.file-drag-drop .file-drag-drop-inner {
    height: 100%;
    background: $color-primary-lightest;
    border: 2px dashed $color-primary-light;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.file-drag-drop .file-drag-drop-inner .file-drag-drop-inner-row {
    display: flex;
    justify-content: center;
    width: 70%;
    margin: 0 auto;
    word-break: break-all;
}
.file-drag-drop .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-icon {
    margin-left: -40px;
}
.file-drag-drop .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-icon .icon-wrapper {
    position: relative;
    height: 44px;
    width: 44px;
    line-height: 36px;
    margin: 13px 20px;
}
.file-drag-drop .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-icon .icon-wrapper svg {
    padding: 7px;
    height: 30px;
    width: 30px;
    background: $color-primary-light;
    color: $color-primary-lightest;
    border-radius: 100%;
}
.file-drag-drop .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-icon .icon-wrapper:before {
    content: '';
    position: absolute;
    border: 3px solid $color-primary-light;
    top: -5px;
    bottom: -5px;
    right: -5px;
    left: -5px;
    border-radius: 100%;
}
.file-drag-drop .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-icon .icon-wrapper:after {
    content: '';
    position: absolute;
    border: 1px solid $color-primary-light;
    top: -9px;
    bottom: -9px;
    right: -9px;
    left: -9px;
    border-radius: 100%;
}
.file-drag-drop .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}
.file-drag-drop .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-text h1 {
    font-size: 25px;
    color: $color-primary-light;
    margin: 0;
}
.file-drag-drop .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-text h2 {
    margin: 0;
    font-size: 14px;
    color: $color-primary-light;
}
.file-drag-drop .file-drag-drop-inner .file-drag-drop-requirements {
    text-align: center;
    width: 70%;
    margin: 0 auto;
}
.file-drag-drop .file-drag-drop-inner .file-drag-drop-requirements p {
    font-size: 10px;
    color: $color-primary-light;
    margin: 0 0 0 15px;
}
.file-drag-drop .file-drag-drop-inner .file-drag-drop-requirements p span {
    text-transform: lowercase;
}
.file-drag-drop .file-drag-drop-inner .file-drag-drop-requirements p span:after {
    content: ', ';
}
.file-drag-drop .file-drag-drop-inner .file-drag-drop-requirements p span:last-child:after {
    content: '.';
}
.file-drag-drop.invalid .file-drag-drop-inner {
    background-color: $color-delete-background;
}
.file-drag-drop.invalid .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-text h1,
.file-drag-drop.invalid .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-text h2,
.file-drag-drop.invalid .file-drag-drop-inner .file-drag-drop-requirements p {
    color: $color-invalid-border;
}
.file-drag-drop.invalid .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-icon .icon-wrapper svg {
    color: $color-delete-background;
}
.file-drag-drop.invalid .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-icon .icon-wrapper svg {
    background-color: $color-invalid-border;
}
.file-drag-drop.invalid .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-icon .icon-wrapper:before,
.file-drag-drop.invalid .file-drag-drop-inner,
.file-drag-drop.invalid .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-icon .icon-wrapper:after {
    border-color: $color-invalid-border;
}
.file-drag-drop.disabled .file-drag-drop-inner {
    background: rgba($color-invalid, 0.33);
    opacity: 0.5;
}
.file-drag-drop.disabled .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-text h1,
.file-drag-drop.disabled .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-text h2,
.file-drag-drop.disabled .file-drag-drop-inner .file-drag-drop-requirements p {
    color: $color-primary;
}
.file-drag-drop.disabled .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-icon .icon-wrapper svg {
    color: $color-primary;
}
.file-drag-drop.disabled .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-icon .icon-wrapper svg {
    background-color: rgba($color-invalid, 0.33);
}
.file-drag-drop.disabled .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-icon .icon-wrapper:before,
.file-drag-drop.disabled .file-drag-drop-inner,
.file-drag-drop.disabled .file-drag-drop-inner .file-drag-drop-inner-row .file-drag-drop-icon .icon-wrapper:after {
    border-color: $color-primary;
}

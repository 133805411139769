
/*
  Tooltip
*/
.tooltip {
}
.tooltip .arrow:before {
    border-top-color: $color-primary-dark;
}
.tooltip .tooltip-inner {
    font-size: 13px;
    color: $white;
    background: $color-primary-dark;
}
.tooltip .tooltip-inner strong {
    color: $white;
    font-weight: bold;
}

/**
    Media content upload
*/

.media-content-upload-wrapper {
}
.media-content-upload-wrapper .media-content-upload {
    margin-bottom: 2rem;
}
.media-content-upload-wrapper .media-content-upload .form-label {
    font-size: 15px;
    margin-bottom: 15px;
    color: $color-primary-dark;
}
.media-content-upload-wrapper .media-content-upload .form-label span {
    display: inline-block;
    font-size: 13px;
    color: $color-subsidiary-lighter;
    font-style: italic;
}
.media-content-upload-wrapper .media-content-upload .uploaded-image {
    width: 170px;
    height: 165px;
    background: $white;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,10,0.1);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,10,0.1);
    box-shadow: 0px 2px 5px 0px rgba(0,0,10,0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}
.media-content-upload-wrapper .media-content-upload .uploaded-image svg {
    color: $color-subsidiary-lighter;
    font-size: 35px;
    text-align: center;
}
.media-content-upload-wrapper .media-content-upload .uploaded-image p {
    color: $color-subsidiary-lighter;
    font-size: 13px;
    text-align: center;
    padding: 10px 20px 0;
}
.media-content-upload-wrapper .media-content-upload .uploaded-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 10px;
}
.media-content-upload-wrapper .media-content-upload .uploaded-image-location p {
    font-size: 15px;
    margin: 0;
    color: $color-primary-dark;
}
.media-content-upload-wrapper .media-content-upload .uploaded-image-location .uploaded-image-location-wrapper {
    display: flex;
    background: $white;
    width: 170px;
    height: 40px;
    line-height: 40px;
    border: 1px solid $offwhite;
    border-radius: 4px;
}
.media-content-upload-wrapper .media-content-upload .uploaded-image-location .uploaded-image-location-wrapper a {
    font-size: 13px;
    width: 50%;
    color: $color-subsidiary-lighter;
    text-decoration: none!important;
    height: 100%;
    text-align: center;
}
.media-content-upload-wrapper .media-content-upload .uploaded-image-location .uploaded-image-location-wrapper a:hover,
.media-content-upload-wrapper .media-content-upload .uploaded-image-location .uploaded-image-location-wrapper a.active {
    color: $color-primary-dark;
}
.media-content-upload-wrapper .media-content-upload .uploaded-image-location .uploaded-image-location-wrapper a:hover svg,
.media-content-upload-wrapper .media-content-upload .uploaded-image-location .uploaded-image-location-wrapper a.active svg {
    color: $color-primary-dark;
}
.media-content-upload-wrapper .media-content-upload .uploaded-image-location .uploaded-image-location-wrapper svg {
    color: $color-subsidiary-lighter;
    margin-right: 5px;
}
.media-content-upload-wrapper .media-content-upload .media-drag-drop {
    margin: 0;
    padding: 15px;
    height: 100%;
}
.media-content-upload-wrapper .media-content > .form-label {
    font-size: 20px!important;
    margin-bottom: 20px;
}
.media-content-upload-wrapper .media-content .media-content-block {
    display: flex;
    background: $white;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,10,0.1);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,10,0.1);
    box-shadow: 0px 2px 5px 0px rgba(0,0,10,0.1);
    margin-bottom: 20px;
}
.media-content-upload-wrapper .media-content .media-content-block .left {
    width: 70px;
}
.media-content-upload-wrapper .media-content .media-content-block .left .drag {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.media-content-upload-wrapper .media-content .media-content-block .left .drag svg {
    transform: rotate(45deg);
    font-size: 20px;
}
.media-content-upload-wrapper .media-content .media-content-block .right {
    width: 100%;
}
.media-content-upload-wrapper .media-content .media-content-block .right .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 30px;
    border: 1px solid $offwhite;
    border-bottom: none;
}
.media-content-upload-wrapper .media-content .media-content-block .right .head .title {
    font-size: 13px;
    color: $color-primary-dark;
}
.media-content-upload-wrapper .media-content .media-content-block .right .head .actions a {
    font-size: 13px;
    height: 28px;
    width: 28px;
    display: inline-block;
    text-align: center;
    line-height: 28px;
    border-radius: 100%;
    border: 1px solid transparent;
    margin-left: 10px;
}
.media-content-upload-wrapper .media-content .media-content-block .right .head .actions a.edit {
    border-color: $color-edit;
    background: $color-edit-background;
}
.media-content-upload-wrapper .media-content .media-content-block .right .head .actions a.edit:hover {
    background: $color-edit;
}
.media-content-upload-wrapper .media-content .media-content-block .right .head .actions a.edit svg {
    color: $color-edit;
}
.media-content-upload-wrapper .media-content .media-content-block .right .head .actions a.edit:hover svg {
    color: $color-edit-background;
}
.media-content-upload-wrapper .media-content .media-content-block .right .head .actions a.delete {
    border-color: $color-invalid-border;
    background: $color-delete-background;
}
.media-content-upload-wrapper .media-content .media-content-block .right .head .actions a.delete svg {
    color: $color-invalid-border;
}
.media-content-upload-wrapper .media-content .media-content-block .right .body {
    border: 1px solid $offwhite;
    padding: 10px 5px;
    display: flex;
}
.media-content-upload-wrapper .media-content .media-content-block .right .body .inner-content {
    width: 50%;
    background: $offwhite;
    padding: 30px 25px;
    margin: 0 5px;
}
.media-content-upload-wrapper .media-content .media-content-block .right .body .inner-content:only-child {
    width: 100%;
}
.media-content-upload-wrapper .media-content .media-content-block .right .body .inner-content.text {
}

.media-content-upload-wrapper .media-content .media-content-block .right .body .inner-content.text table {
    margin-bottom: $spacing-small;
    td {
        border: 1px solid rgba($black, 0.2);
        padding: $spacing-small;
    }
}
.media-content-upload-wrapper .media-content .media-content-block .right .body .inner-content.text p.title {
    color: $color-subsidiary-darker;
    font-size: 20px;
    margin-bottom: 20px
}
.media-content-upload-wrapper .media-content .media-content-block .right .body .inner-content.text p {
    color: $color-subsidiary-darker;
    font-size: 13px;
}
.media-content-upload-wrapper .media-content .media-content-block .right .body .inner-content.text table {
    color: $color-subsidiary-darker;
    font-size: 13px;
}
.media-content-upload-wrapper .media-content .media-content-block .right .body .inner-content.text ol,
.media-content-upload-wrapper .media-content .media-content-block .right .body .inner-content.text ul {
    color: $color-subsidiary-darker;
    font-size: 13px;
}
.media-content-upload-wrapper .media-content .media-content-block .right .body .inner-content.image {
    display: flex;
    align-items: center;
}
/* .media-content-upload-wrapper .media-content .media-content-block .right .body .inner-content.image img { width: 100%; height: 250px; object-fit: contain; }*/
.media-content-upload-wrapper .media-content .media-content-block .right .body .inner-content.image img {
    width: 100%;
    object-fit: contain;
}

.media-content-upload-wrapper .media-content .media-content-block .right .body .inner-content.text iframe {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
}

.media-content-upload-wrapper .media-content-upload .uploaded-image.is-invalid {
    background: $color-invalid-background;
    border: 1px solid $color-invalid-border
}
.media-content-upload-wrapper .media-content-upload .uploaded-image.is-invalid p {
    color: $color-invalid-border
}
.media-content-upload-wrapper .media-content-upload .uploaded-image .delete {
    position: absolute;
    left: 135px;
    top: 0px;
    font-size: 13px;
    height: 28px;
    width: 28px;
    display: inline-block;
    text-align: center;
    line-height: 28px;
    border-radius: 100%;
    border: 1px solid $color-invalid-border;
    background: $color-delete-background;
}
.media-content-upload-wrapper .media-content-upload .uploaded-image .delete svg {
    color: $color-invalid-border;
    font-size: inherit;
}

.media-content-upload-wrapper .media-content-block .inner-content {
    position: relative;
}
.media-content-upload-wrapper .media-content-block .inner-content img {
    border: 1px dotted rgba(0, 0, 0, .3);
}
.media-content-upload-wrapper .media-content-block .inner-content .delete {
    position: absolute;
    right: 35px;
    top: 17.5px;
    font-size: 13px;
    height: 28px;
    width: 28px;
    display: inline-block;
    text-align: center;
    line-height: 28px;
    border-radius: 100%;
    border: 1px solid $color-invalid-border;
    background: $color-delete-background;
}
.media-content-upload-wrapper .media-content-block .inner-content .delete svg {
    color: $color-invalid-border;
}
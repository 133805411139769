

/**
    Permissions modal
*/
.user-to-permissions-select {
    display: flex;
    width: 100%;
    background: $offwhite;
}
.user-to-permissions-select .user-select {
    width: 100%;
}
.user-to-permissions-select .user-select .user-select__control {
    background: transparent;
    border-color: transparent;
    border-radius: 0;
    box-shadow: none!important;
}
.user-to-permissions-select .add {
    height: 38px;
    padding: 0 15px 0 5px;
    display: flex;
    justify-content: center;
    align-items:center;
}
.user-to-permissions-select .add svg {
    font-size: 17px;
    height: 16px;
    color: $color-primary-dark;
}
.user-to-permissions-select .add.disabled {
    pointer-events: none;
}
.user-to-permissions-select .add.disabled svg {
    color: $color-subsidiary-lighter;
}
.user-to-permissions-select .user-select .user-select__menu {
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    background: $offwhite;
}
.user-to-permissions-select .user-select .user-select__menu .user-select__menu-list .user-select__option--is-focused {
    background: $color-subsidiary-lightest;
}
.user-to-permissions-select .user-select .user-select__menu .user-select__menu-list .user-select__option--is-selected {
    background: $color-subsidiary-lightest;
    color: $black;
    font-weight: 600;
}
.user-to-permissions-selected .user-to-permissions-selected-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid $offwhite;
}

.user-to-permissions-selected-item-icon {
    background: $offwhite;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    svg {
        height: 17px;
        color: $color-subsidiary-darker;
    }
}

.user-to-permissions-selected .user-to-permissions-selected-item p {
    margin: 0;
    color: $color-primary-dark;
    font-weight: 600;

}
.user-to-permissions-selected .user-to-permissions-selected-item a {
    height: 28px;
    width: 28px;
    border: 1px solid $color-invalid-border;
    text-align: center;
    border-radius: 100%;
    background: $color-delete-background;
    display: flex;
    justify-content: center;
    align-items:center;
    transition: all 0.2s ease;
    margin-left:auto;

    &:hover {
        background:  $color-invalid-border;
        svg {
            color: $white;
        }
    }
}
.user-to-permissions-selected .user-to-permissions-selected-item a svg {
    font-size: 14px;
    color: $color-invalid-border;
}


/*
  CMS
*/
.cms {
    position: relative;
    display: flex;
}
.cms .content {
    position: absolute;
    left: 15%;
    right: 0;
    top: 70px;
    bottom: 0;
    z-index: 2;
    overflow: auto;
    padding: 85px 75px 30px;
    display: flex;
    flex-direction: column;
}
.fslightbox-open .cms .content {
    z-index: 5;
    left: 0;
    padding-left: calc(15% + 75px);
    top: 0;
    padding-top: calc(70px + 85px);
}
.cms .content h1 {
    font-size: 21px;
    line-height: 38px;
    margin-bottom: 0px;
    margin-right: 20px;
}
.cms .content .title {
    display: flex;
    align-items: center;
    margin-bottom: $spacing;
    width: 80%;
    @include mq(xl) {
        width: 65%;
    }
}
.cms .content .subtitle {
    color: $color-primary-light;
    font-size: 18px;
    font-weight: 300;
}
.cms .content .overview-text {
    width: 80%;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: lighter;
    @include mq(xl) {
        width: 60%;
    }

}
.cms .content .title.form-title a, .cms .content .title a.go-back {
    color: $color-subsidiary-darker;
    font-size: 14px;
    margin: 5px 0 0 auto;
}
.cms .content .title.form-title a svg, .cms .content .title a.go-back svg {
    margin-right: 5px;
    height: 0.7em;
}


/*
 Table $offwhite
*/

.custom-data-table {
    position: relative;
    padding: 10px 30px 30px;
    background: $white;
    box-shadow: $boxshadow !important;
}

.custom-data-table .table-header {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    text-transform: uppercase;
    height: 18px;
    margin: 15px 0 20px;
    line-height: 18px;

}

.custom-data-table .table-header p {
    font-size: 13px;
    color: $color-primary-light;
    line-height: 19px;
    margin: 0;
    font-weight: 600;
}
.custom-data-table .table-header .custom-search-bar svg {
    margin-right: 10px;
}
.custom-data-table .table-header .custom-search-bar label input {
    height: 19px;
    font-size: 15px;
    padding: 0;
    border: 0;
}
.custom-data-table .react-bootstrap-table {
    //  overflow-x: unset;
}
.custom-data-table .react-bootstrap-table * {
    border: none;
}
.custom-data-table .react-bootstrap-table .table {
    font-size: 14px;
    table-layout: auto!important;
    width: calc(100% - 1px);
}
.custom-data-table .react-bootstrap-table .table thead {
}
.custom-data-table .react-bootstrap-table .table thead tr {
}
.custom-data-table .react-bootstrap-table .table thead tr th {
    text-transform: uppercase;
    color: $color-primary-light;
    font-weight: bold;
    background: $color-primary-lightest;
    white-space:nowrap;
}
.custom-data-table .react-bootstrap-table .table thead tr th.first {
    padding-left: 30px;
}
.custom-data-table .react-bootstrap-table .table thead tr th span {
    display: inline-flex;
    flex-direction: column;
    margin-left: 5px;
}

.custom-data-table .react-bootstrap-table .table thead tr th span[class^="order-"]:before {
    content: "\1F781";
    margin: 0;
    line-height: 1px;
    margin-top: -2px;
    margin-bottom: 1px;
    font-size: 13px;

}

.custom-data-table .react-bootstrap-table .table thead tr th span[class^="order-"]:after {
    content: "\1F783";
    line-height: 1px;

    font-size: 13px;
    line-height: 1px;
}


.custom-data-table .react-bootstrap-table .table thead tr th span[class^="caret-"]:before {
    content: "\1F781";
    margin: 0;
    line-height: 1px;
    margin-top: -1px;
    margin-bottom: 1px;
    font-size: 13px;
    /* font-family: "Font Awesome 5 Free";
     font-weight: 900;
     content: "\f0d8";
     font-size: 13px;
     line-height: 1px;
     margin: 0; */
}
.custom-data-table .react-bootstrap-table .table thead tr th span[class^="caret-"]:after {
    content: "\1F783";
    line-height: 1px;
    font-size: 13px;
    // font-family: "Font Awesome 5 Free";
    /* font-weight: 900;
     content: "\f0dd";
     font-size: 13px;
    */

}
.custom-data-table .react-bootstrap-table .table tbody {
    border: 1px solid $offwhite;
    border-bottom: none;
    border-top: none;
}
.custom-data-table .react-bootstrap-table .table tbody tr {
    box-shadow: inset 0 -1px 0 $offwhite;
}
.custom-data-table .react-bootstrap-table .table tbody tr td {
    position: relative;
    color: $color-primary-dark;
    vertical-align: middle;
    padding: 11px 0.75rem;
    font-size: 14px;
    // overflow: hidden;
    text-overflow: ellipsis; /*white-space: nowrap;*/
}

.custom-data-table .react-bootstrap-table .table tbody tr td.react-bootstrap-table-editing-cell input{
    font-size: 14px !important;
    padding: 0.5rem !important;
    margin: 0 !important;
    height: 1.5rem;
    color: $color-primary-dark;
    max-width: 60% !important;
    background: transparent;
    box-shadow: 0px 3px 6px rgba($black, 0.08) !important;
}


.custom-data-table .react-bootstrap-table .table tbody tr td:not(:last-child, .react-bootstrap-table-editing-cell) {

    /*max-width: 0; background: linear-gradient(to left, $white, $color-primary-dark 35%, $color-primary-dark 35%, $color-primary-dark 75%); 
    -webkit-background-clip: text; -moz-background-clip: text; -webkit-text-fill-color: transparent; -moz-text-fill-color: transparent;*/ }
.custom-data-table .react-bootstrap-table .table tbody tr td.first {
    padding-left: 30px;
}
.custom-data-table .react-bootstrap-table .table tbody tr td.name {
    color: $color-primary-dark;
    font-size: 16px;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .add {
}
.custom-data-table .react-bootstrap-table .table tbody tr td .add a {
    color: $color-primary-dark;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .add a svg {
    margin-right: 8px;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .add a svg {
    margin-right: 8px;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions {
    white-space: nowrap;
    justify-content: end;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a {
    font-size: 13px;
    height: 28px;
    width: 28px;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    border-radius: 100%;
    margin-right: 10px;

    &.link-auto {
        width: auto;
        margin-right: 0;
        padding: 0 10px !important;
    }
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a:hover {
    border-width: 2px;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a svg {
    transition: 0.2s;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.edit {
    border: 1px solid $color-edit;
    background: $color-edit-background;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.edit:hover {
    backgroun: 1px solid $color-edit;
    background: $color-edit-background;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.edit svg {
    color: $color-edit;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.edit:hover svg {
    color: $color-edit;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .before-action {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin: 1px 8px 0;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .before-action a {
    font-size: 13px;
    color: $color-primary-dark;
    width: 10px;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .before-action.info {
    left: 0;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .before-action.info a {
}
.custom-data-table .react-bootstrap-table .table tbody tr td .before-action.user {
    left: 26px;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .before-action.user a {
}
.custom-data-table .react-bootstrap-table .table tbody tr td .language-single {
}
.custom-data-table .react-bootstrap-table .table tbody tr td .language-single span {
    height: 23px;
    min-width: 20px;
    display: inline-block;
    line-height: 23px;
    text-align: center;
    background: $color-primary-lightest;
    border-radius: 5px;
    font-weight: 600;
    color: $color-primary-dark;
    font-size: 13px;
    text-transform: uppercase;
    padding: 0 4px;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .website-link {
    line-height: 28px;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .website-link a {
    display: flex;
    color: $color-primary-dark;
    text-decoration: underline;
    font-size: 16px;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .website-link a .arrow {
    height: 28px;
    width: 28px;
    border: 1px solid $color-primary;
    line-height: 28px;
    font-size: 16px;
    text-align: center;
    border-radius: 100%;
    margin-right: 25px;
    -webkit-box-shadow: 0px 3px 6px 0px rgba($black,0.08)!important;
    -moz-box-shadow: 0px 3px 6px 0px rgba($black,0.08)!important;
    box-shadow: 0px 3px 6px 0px rgba($black,0.16)!important;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .website-link a .arrow svg {
    color: $color-primary;
    font-weight: lighter;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .website-link:hover a {
    color: $color-primary!important;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .website-link:hover a .arrow {
    background: $color-primary!important;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .website-link:hover a .arrow svg {
    color: $white !important;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .website-link {
    line-height: 23px;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .website-link a {
    position: relative;
    font-size: 14px;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .website-link a .arrow {
    position: relative;
    left: -28px;
    height: 20px;
    width: 20px;
    background: $white;
    font-size: 11px;
    line-height: 20px;
    margin-right: 0;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .website-link a .arrow svg {
}
.custom-data-table .react-bootstrap-table .table tbody tr td .settings {
    text-align: right;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .settings .setting {
    margin: 0 5px;
    color: $color-primary-dark;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .disabled {
    opacity: .4;
    pointer-events: none;
    cursor: default;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.default {
    background: transparent;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.default svg {
    color: $color-primary-dark;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.favorite {
    color: $color-inactive !important;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.favorite svg {
    color: $color-inactive !important;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.delete {
    border: 1px solid $color-invalid-border;
    background: $color-delete-background;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.delete svg {
    color: $color-invalid-border;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.delete.disabled {
    opacity: .5;
    pointer-events: none;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.url {
    border: 1px solid $color-primary;
    background: $white;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.url:hover {
    background: $color-primary;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.url svg {
    color: $color-primary;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.url:hover svg {
    color: $white;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.active {
    border: 1px solid $color-primary;
    background: $color-primary-lighter
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.active svg {
    color: $color-primary;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.active:hover {
    border: 1px solid $color-primary;
    background:  $color-primary
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.active:hover svg {
    color: $color-primary-lighter;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.inactive {
    border: 1px solid $color-inactive;
    background: $color-inactive-background
}
.custom-data-table .react-bootstrap-table .table tbody tr td .actions a.inactive svg {
    color: $color-inactive;
}
.custom-data-table .react-bootstrap-table .table tbody tr td.languages-wrapper {
    text-overflow: unset;
    overflow: unset;
    white-space: unset;
}
/*.custom-data-table .react-bootstrap-table .table tbody tr td .languages { display: flex; }
.custom-data-table .react-bootstrap-table .table tbody tr td .languages .language { height: 23px; background: $color-primary-lightest; padding: 0 7px; border-radius: 5px; margin: 0 5px; }
.custom-data-table .react-bootstrap-table .table tbody tr td .languages .language p { margin: 0; text-transform: uppercase; color: $color-primary-dark; font-weight: bold; }
.custom-data-table .react-bootstrap-table .table tbody tr td .languages .language a { font-size: 10px; color: $color-primary-dark; }
.custom-data-table .react-bootstrap-table .table tbody tr td .languages .language-add {  height: 23px;  padding: 0 7px; border-radius: 5px; background: $offwhite; }
.custom-data-table .react-bootstrap-table .table tbody tr td .languages .language-add a { color: $color-subsidiary-lighter; } */
.custom-data-table .react-bootstrap-table .table tbody tr td .react-select__control {
    border: 1px solid $color-subsidiary-lighter;
}
.custom-data-table .react-bootstrap-table .table tbody tr .reset-expansion-style {
    padding: 0;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style {
    padding: 0;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table {
    margin-bottom: 40px;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table thead {
    display: none;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table tbody {
    border: none;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table tbody tr {
    background: $offwhite;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table tbody tr td {
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table tbody tr td.first {
    padding-left: 60px;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table tbody tr td .add {
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table tbody tr td .add a {
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table tbody tr td .actions a {
    background: transparent;
    border-color: $color-primary-dark;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table tbody tr td .actions a svg {
    color: $color-primary-dark;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table tbody tr td .actions a.active {
    border: 2px solid $color-primary;
    background: $color-primary-lighter;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table tbody tr td .actions a.active {
    border: 2px solid $color-primary;
    background: $color-primary-lighter;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table tbody tr td .actions a.active svg {
    color: $color-primary;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table tbody tr td .actions a.inactive {
    border: 1px solid $color-inactive;
    background: rgba(255, 244, 229, .2);
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .table tbody tr td .actions a.inactive svg {
    color: $color-inactive;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table-wrapper,
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .reset-expansion-style {
    background: $white;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table {
    width: 89.1%;
    margin: auto 0 auto auto;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table {
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table {
    margin-bottom: 5px;
    overflow: unset;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody {
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr {
    background: transparent;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td {
    overflow: unset;
}
/*.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .languages {  }
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .languages .language { background: $color-primary-lightest; }
.custom-data-table.user .react-bootstrap-table .table tbody tr td:last-child { padding: 0; }
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .languages .language p { color: $color-primary-dark; font-weight: 600; }
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .languages .language p a { color: $color-primary-dark; }
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .languages .language-add { background: $offwhite; }
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .languages .language-add a { text-decoration: none; color: $color-subsidiary-lighter;  } */
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .actions {
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .actions .download svg {
    color: $color-primary-dark;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .actions .check {
    border: 1px solid $color-invalid;
    background: rgba(134, 193, 50, .2)
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .actions .check svg {
    color: $color-invalid;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .actions .edit {
    border: 1px solid $color-edit;
    background: $color-edit-background;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .actions .edit:hover {
    background: $color-edit;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .actions .edit svg {
    color: $color-edit;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .actions .edit:hover svg {
    color: $color-edit-background;
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .actions .delete {
    border: 1px solid $color-invalid-border;
    background: rgba($color-invalid-background, .2);
}
.custom-data-table .react-bootstrap-table .table tbody tr .row-expansion-style .custom-sub-data-table .react-bootstrap-table .table tbody tr td .actions .delete svg {
    color: $color-invalid-border;
}
.custom-data-table .react-bootstrap-table .table tbody tr.expanded {
    background: $color-primary-dark;
}
.custom-data-table .react-bootstrap-table .table tbody tr.expanded td.name {
    color: $white;
}
.custom-data-table .react-bootstrap-table .table tbody tr.expanded td .language-single span {
    color: $color-primary-dark;
    background: $color-primary-light !important;
}
.custom-data-table .react-bootstrap-table .table tbody tr.expanded td .add a {
    color: $white;
}
.custom-data-table .react-bootstrap-table .table tbody tr.expanded td .actions a {
    background: transparent;
    border-color: $white;
}
.custom-data-table .react-bootstrap-table .table tbody tr.expanded td .actions a svg {
    color: $white;
}
.custom-data-table .pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -60px;
    box-shadow: $boxshadow !important;
}
.custom-data-table .pagination .page-item.active .page-link {
    background: $color-primary;
    color: $white;
}
.custom-data-table .pagination .page-item .page-link {
    padding: 0;
    height: 32px;
    line-height: 32px;
    width: 32px;
    text-align: center;
    font-size: 13px;
    color: $color-primary-dark;
    border: 1px solid $offwhite;
    border-top: 0;
    border-bottom: 0;
    box-shadow: none!important;
    outline: unset!important;
}
.custom-data-table .pagination .page-item[title="next page"] .page-link,
.custom-data-table .pagination .page-item[title="previous page"] .page-link {
    width: unset;
    padding: 0 11px;
    color: $color-primary-dark;
    border: 0;
}
.custom-data-table p.not-found {
    margin: 10px 0;
}
.custom-data-table .table-wrapper {
    width: 100%;
}
.custom-data-table .react-bootstrap-table .table tbody tr td .react-select-container {
}
.custom-data-table .react-bootstrap-table .table tbody tr td .react-select-container .react-select__control {
    min-height: unset
}
.custom-data-table .table-header .toggle-tree button {
    -webkit-appearance: none;
    background: none;
    border: 0;
    color: $color-subsidiary-lighter;
    outline: none!important;
}
.custom-data-table .table-header .toggle-tree button:disabled,
.custom-data-table .table-header .toggle-tree button:hover {
    color: $color-primary-dark;
}

/* Sub row */
.custom-data-table .react-bootstrap-table .table tbody tr.sub-row {
    background: $offwhite;
    margin-left: 40px;
}


/*
   Tab Tables
*/
.tab-tables {
}
.tab-tables .list-group {
    flex-direction: row;
    display: inline-flex;
}
.tab-tables .list-group .list-group-item {
    border: 0;
    border-radius: 0;
    background: unset;
    color: $color-primary-dark;
    width: unset;
    margin: 0 40px 30px 0;
    padding: 10px;
}
.tab-tables .list-group .list-group-item:hover,
.tab-tables .list-group .list-group-item.active {
    border-bottom: 2px solid $color-primary;
}
.tab-tables .tab-content {
}
.tab-tables .tab-content .tab-pane {
}
.tab-tables .tab-content .tab-pane.active {
}
.tab-tables .tab-content .tab-pane .custom-data-table {
}
.tab-tables .tab-content .tab-pane.active .custom-data-table {
}



/*
    Media Table
*/
.custom-data-table.media .react-bootstrap-table .table {
}
.custom-data-table.media .react-bootstrap-table .table thead {
}
.custom-data-table.media .react-bootstrap-table .table thead tr {
}
.custom-data-table.media .react-bootstrap-table .table thead tr th {
}
.custom-data-table.media .react-bootstrap-table .table thead tr th span {
}
.custom-data-table.media .react-bootstrap-table .table thead tr th span:before {
}
.custom-data-table.media .react-bootstrap-table .table thead tr th span:after {
}
.custom-data-table.media .react-bootstrap-table .table tbody {
}
.custom-data-table.media .react-bootstrap-table .table tbody tr {
}
.custom-data-table.media .react-bootstrap-table .table tbody tr td {
}
.custom-data-table.media .table-header .toggle-show-image button {
    -webkit-appearance: none;
    background: none;
    border: 0;
    color: $color-subsidiary-lighter;
    outline: none!important;
}
.custom-data-table.media .table-header .toggle-show-image button:disabled,
.custom-data-table.media .table-header .toggle-show-image button:hover {
    color: $color-primary-dark;
}
.custom-data-table.media .react-bootstrap-table .table tbody tr td .actions a {
    background: $color-delete-background;
}
.custom-data-table.media.show-media .react-bootstrap-table {
    background: $color-primary-lightest;
    padding: 30px 50px;
}
.custom-data-table.media.show-media .react-bootstrap-table .table {
}
.custom-data-table.media.show-media .react-bootstrap-table .table thead {
    display: none;
}
.custom-data-table.media.show-media .react-bootstrap-table .table tbody {
    display: flex;
    flex-wrap: wrap;
}
.custom-data-table.media.show-media .react-bootstrap-table .table tbody tr {
    flex: 0 0 calc(20% - 60px);
    position: relative;
    width: calc(20% - 60px);
    margin: 30px;
    display: flex;
    flex-direction: column;
    border: 0;
    box-shadow: $boxshadow !important;
}
.custom-data-table.media.show-media .react-bootstrap-table .table tbody tr td {
    padding: 0;
}
.custom-data-table.media.show-media .react-bootstrap-table .table tbody tr td .image {
    background: $color-primary-light;
}
.custom-data-table.media.show-media .react-bootstrap-table .table tbody tr td .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    &.icon-over {
        position: absolute;
        top: 0;
        left: 0;
        right:0;
        bottom: 0;
        background: transparent;
    }

    &.icon-noimage {
        background: $color-primary-light !important;
    }
}


.custom-data-table.media.show-media .react-bootstrap-table .table tbody tr td .icon:hover,
.custom-data-table.media.show-media .react-bootstrap-table .table tbody tr td .icon:focus,
.custom-data-table.media.show-media .react-bootstrap-table .table tbody tr td .image:hover,
.custom-data-table.media.show-media .react-bootstrap-table .table tbody tr td .image:focus {
    cursor: pointer;
}
.custom-data-table.media.show-media .react-bootstrap-table .table tbody tr td .image img,
.custom-data-table.media.show-media .react-bootstrap-table .table tbody tr td .icon {
    width: 100%;
    height: 110px;
    object-fit: cover;
    background: $color-primary-light;
}
.custom-data-table.media.show-media .react-bootstrap-table .table tbody tr td .icon svg {
    font-size: 46px;
    color: $white;
}
.custom-data-table.media.show-media .react-bootstrap-table .table tbody tr td:nth-child(2) {
    font-size: 12px;
    color: $color-primary-dark;
    background: $white;
    padding: 13px 10px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.custom-data-table.media.show-media .react-bootstrap-table .table tbody tr td:nth-child(3) {
    position: absolute;
    top: -16px;
    right: 20px;
}
.custom-data-table.media.show-media .react-bootstrap-table .table tbody tr td .actions a {
    height: 34px;
    width: 34px;
    line-height: 32px;
}
.custom-data-table.media.show-media .table-header .toggle-show-image {
}


/*
  Album table
*/
.custom-data-table .table-album {
    display: flex;
    flex-wrap: wrap;
    background: $color-primary-lightest;
    padding: 0 40px 5px;
}
.custom-data-table .table-album .image {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed $color-subsidiary-lighter;
    margin: 5px 2px 0 2px;
    position: relative;

}
.custom-data-table .table-album .image:nth-child(n+7) {
    // margin-top:10px;
}
.custom-data-table .table-album .image:nth-child(1),
.custom-data-table .table-album .image:nth-child(2),
.custom-data-table .table-album .image:nth-child(3),
.custom-data-table .table-album .image:nth-child(4),
.custom-data-table .table-album .image:nth-child(5) {
    //margin-bottom: 10px;
}
.custom-data-table .table-album .image img, .custom-data-table .table-album .image video {
    width: 150px;
    height: 110px;
    object-fit: cover;
    pointer-events: none;
}
.custom-data-table .table-album .image .delete {
    position: absolute;
    right: 35px;
    top: 27.5px;
    font-size: 13px;
    height: 28px;
    width: 28px;
    display: inline-block;
    text-align: center;
    line-height: 28px;
    border-radius: 100%;
    border: 1px solid $color-invalid-border;
    background: $color-delete-background;
}
.custom-data-table .table-album .image .delete svg {
    color: $color-invalid-border;
}
.custom-data-table .table-album .image .overlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.custom-data-table .table-album .image .overlay svg {
    color: $white;
    font-size: 46px;
}
.custom-data-table .table-album .icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-data-table .table-album .icon:hover,
.custom-data-table .table-album .icon:focus,
.custom-data-table .table-album .image:hover,
.custom-data-table .table-album .image:focus {
    cursor: pointer;
}
.custom-data-table .table-album .icon {
    width: 150px;
    height: 110px;
    object-fit: cover;
    background: $color-primary-dark;
}
.custom-data-table .table-album .icon svg {
    font-size: 46px;
    color: $white;
    pointer-events: none;
}
.custom-data-table.media-album {
}
.custom-data-table.media-album .status {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.custom-data-table.media-album .status .status-text {
    margin: 0;
    font-size: 14px;
    color: $color-primary-dark;
}
.custom-data-table.media-album .status .progress {
    width: 145px;
    height: 8px;
    border-radius: 10px;
    background: $color-primary-dark;
}
.custom-data-table.media-album .status .progress .progress-bar {
    background: $color-primary;
}
.custom-data-table.media-album .bottom {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    .btn {
        @include mq (lg) {
            min-width: 150px;
        }
        & + .btn {
            margin-left: 5px;
        }
    }
}
.custom-data-table.media-album .bottom .status {
    width: 230px;
    padding: 0 20px;
    margin-left: auto;


}
.custom-data-table.media-album .bottom .total-size {
    width: 230px;
    padding: 0 15px;
    display: flex;
    align-items: center;
}
.custom-data-table.media-album .bottom .total-size p {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: $color-primary-dark;
}
.custom-data-table.media-album .react-bootstrap-table .table tbody tr td .actions a.delete {
    margin: 0;
}


.table-dropdown {
    position: relative;

    .table-dropdown-items {
        position: absolute;
        top: 2rem;
        left: 0rem;
        z-index: 1;
        padding: 0;
    }
    
    .table-dropdown-item {
        padding: 0;
    }
    
    .table-dropdown-link {
        margin-right: 0 !important;
        padding: 0.2rem 0.8rem !important;
        display:  block !important;
        height: auto !important;
        width: 100% !important;
        color: $color-primary-dark!important;
        transition: all 0.2s ease;
        border-radius: 0 !important;
        font-size: 14px !important;
        text-align: left !important;
        svg {
             color: $color-primary-dark!important;
        }
        &:hover {
             background-color:$color-primary-lightest !important;
             color: $color-primary-dark!important;
           
        }
    }
}


/*
  Top-nav
*/
.top-nav {
    width: 85%;
    order: 2;
    z-index: 4;
    display: flex;
    background: $white;
    height: 70px;
    justify-content: space-between;
}
.top-nav  {
}
.top-nav .navbar-nav .nav-link {
    color: $color-subsidiary-darker;
    padding: 0 20px;
    height: 70px;
    line-height: 70px;
    font-size: 15px;
}
.top-nav .navbar-nav .nav-link.active,
.top-nav .navbar-nav .nav-link:hover,
.top-nav .navbar-nav .nav-link:focus {
    background: $color-primary-lightest;
}
.top-nav .navbar-nav .nav-link svg {
    margin-right: 10px;
}
.top-nav .actions {
    display: flex;
    //padding: 0 1rem;
    margin-right: $spacing;
}

.top-nav .logo {
    margin: 1rem 0;
}

.top-nav .actions .user {
    padding: 0 1rem;
}
.top-nav .actions .lang-switch {
}
.top-nav .actions .lang-switch ul {
}
.top-nav .actions .lang-switch ul li {
    line-height: 70px;
    margin: 0 5px;
}
.top-nav .actions .lang-switch ul li a {
}
.top-nav .actions .user {
    display: flex;
    line-height: 70px;
}
.top-nav .actions .user:before {
    content: '';
    height: 28px;
    width: 1px;
    background: $color-subsidiary-lightest;
    display: block;
    margin: 21px 25px 21px 15px;
}
/*.top-nav .actions .user p { margin: 0; line-height: 70px; font-size: 15px; color: $color-subsidiary-darker; }*/
.top-nav .actions .user .dropdown {
}
.top-nav .actions .user .dropdown button {
    background: transparent;
    font-size: 15px;
    color: $color-subsidiary-darker;
    border: 0;
    border-radius: 0;
    padding: 0;
    height: 70px;
    display: flex;
    line-height: 70px;
    box-shadow: none!important;
}
.top-nav .actions .user .dropdown button svg {
    order: 2;
    margin: auto 10px auto 35px;
}
.top-nav .actions .user .dropdown button:after {
    display: none;
}
.top-nav .actions .user .dropdown button img {
    order: 3;
    margin: auto;
}
.top-nav .actions .user .dropdown .dropdown-menu {
    z-index: 99999999;
    left: unset;
    right: 0;
    padding: 0;
}
.top-nav .actions .user .dropdown .dropdown-menu .dropdown-item {
    height: 40px;
    line-height: 30px;
    
    &:hover {
       background: $color-primary-lightest !important; 
    }
    
    &.active {
        background: $color-primary-light !important;
        border: none !important;
    }
}
.top-nav .actions .user .dropdown .dropdown-menu .dropdown-item:focus {
    background: unset;
    color: unset;
}
.top-nav .dropdown-navbar {
    width: 240px;
    margin: auto 0 auto 20px;
}
.top-nav .dropdown-navbar .react-select-container .react-select__control .react-select__single-value svg,
.top-nav .dropdown-navbar .react-select__menu .react-select__menu-list .react-select__option svg {
    margin-right: 10px;
}


.mobile-leave-settings {
    .nav-link {
        height: 70px;
        line-height: 50px;
        color: $color-subsidiary-darker;
        font-size: 15px;
        white-space: nowrap;
        svg {
            margin: auto 10px auto 0px;
        }

        &:hover {
             background: $color-primary-lightest;
           // color:$color-primary;
        }

        @include mq(0,md) {
            span {
                display: none;
            }
        }

        @include mq(md) {
            svg {
                margin-right: 10px;
            }
        }
    }
}
.footer {
    margin-top: auto;
    text-align: center;
    color: $color-text-light;
    font-size: $font-size-small;
    padding-top: $spacing-larger;
    
    p {
        margin-bottom: 0;
    }
    
    a {
          color: $color-text-light;
    }
}
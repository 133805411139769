
.password-requirements {
    background: $color-primary-lightest;
    padding: 20px;
    border-radius: 10px;
}
.password-requirements ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.password-requirements ul li {
    color: $color-primary-dark;
    font-size: 13px;
    margin-bottom: 5px;
}
.password-requirements ul li svg {
    margin-right: 10px;
}
.password-requirements ul li.title {
    font-weight: bold;
    color: $color-primary-dark;
    margin-bottom: 10px;
}
.password-requirements ul li.correct {
    color: $color-primary;
}
.password-requirements ul li:last-child {
    margin-top: 15px;
}
/*
  Policy
*/
.policy-nav {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 12px;
    color: $color-subsidiary-lighter;
    margin-top: 60px;
}
.policy-nav p {
    margin-bottom: 5px;
}
.policy-nav ul {
    list-style: none;
    display: flex;
    padding-left: 0;
    justify-content: center;
}
.policy-nav ul li:after {
    content: '•';
    margin: 0 5px;
}
.policy-nav ul li:last-child:after {
    display: none;
}
.policy-nav ul li a {
    color: $color-subsidiary-lighter;
}

/**
    Cookie disclaimer
*/
#cookie-disclaimer {
    background: $color-subsidiary-darkest !important;
    color: $white;
    position: relative;
    margin-bottom: 0;
    padding: 20px 0 15px;
    text-align: center;
}
#cookie-disclaimer .cross {
    display: inline-block;
    margin-left: 15px;
    position: absolute;
    right: 10px;
    top: 5px;
}
#cookie-disclaimer p {
    margin-bottom: 10px;
    font-size: 12px;
}
#cookie-disclaimer a {
    color: $color-subsidiary;
    font-size: 12px;
}
#cookie-disclaimer #accept-btn {
    display: inline;
    color: $white;
    background: $color-primary;
    border-color: $color-primary;
}
#cookie-disclaimer #accept-btn:hover,
#cookie-disclaimer #accept-btn:focus {
    border-color: $color-primary!important;
    color: $color-primary!important;
}
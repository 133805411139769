/*
  Dashed ProgressBar
*/
.dashed-progressbar {
    display: flex;
    width: 50%;
    margin: auto;
}
.dashed-progressbar .dash {
    background: $color-subsidiary-lighter;
    font-size: 0;
    height: 3px;
    width: 100%;
    margin: 0 2px;
}
.dashed-progressbar .dash[data-active="true"] {
    background: $color-primary;
}

.alert {
    > :last-child {
        margin-bottom: 0;
    }
}

.alert-danger {
    background: $color-delete-background;
    border-color: $color-delete-border;
    color: $color-delete;
}
.table-dropdown {

    &-items {
        display: list-item;

        list-style: none;

        margin: 0;
        padding: 0.2rem 0.5rem;

        background: $white;
        box-shadow: $boxshadow !important;
    }

    &-item {
        padding: $spacing 0;
    }

    &-link {
        display: inline !important;
        
        font-size: 16px !important;

        &:focus {
            box-shadow: none !important;
        }

        &:hover {
            svg {
                color: $color-subsidiary !important;
            }
        }
    }

    &-icon {
        margin-right: $spacing;
    }
}

/**
    Light box
*/
img.fslightbox-source {
    height: auto!important;
}
.lightbox-download {
    width: 300px;
    height: 200px;
    background: $white;
    border-radius: 4px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}
.lightbox-error {
    justify-content: center;
}
.lightbox-error h2 svg {
    font-size: 32px;
}
.lightbox-download h1 {
    margin: 0!important;
    text-align: center;
    font-size: 26px!important;
}
.lightbox-download h2 {
    font-size: 20px;
    text-align: center;
}
.lightbox-download h2 svg {
    margin-right: 10px;
}
.lightbox-download a {
    display: block;
}

.icon-tooltip {
    float: right;
}
.icon-tooltip .info-icon {
    font-size: 15px;
    color: $color-primary-light;
}
.icon-tooltip .info-tooltip {
    z-index: 9999;
    position: absolute;
    display: none;
    background: $color-primary-light;
    color: $white;
    padding: 5px 10px;
    width: 260px;
    top: 125%;
    left: 110%;
    margin-left: 5px;
    margin-top: -43px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 2px 6px 0px rgba($black,0.1);
    -moz-box-shadow: 0px 2px 6px 0px rgba($black,0.1);
    box-shadow: 0px 2px 6px 0px rgba($black,0.1);
}
.icon-tooltip .info-tooltip:before {
    content: '';
    border-style: solid;
    border-width: 8px 8px 8px 0;
    border-color: transparent $color-primary-light transparent transparent;
    position: absolute;
    left: -8px;
    top: 18px;
}
.icon-tooltip .info-tooltip p.title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}
.icon-tooltip .info-tooltip .text p, .info-tooltip .text a {
    font-size: 13px;
    margin-bottom: 0.5rem;
    &:last-child {
        margin-bottom: 0;
    }
}
.icon-tooltip .info-tooltip.show {
    display: block;
}
.icon-tooltip .info-tooltip .link {
    font-size: 13px;
    color: $white;
}
.icon-tooltip .info-tooltip .close {
    font-size: 10px;
    color: $color-primary-dark;
    background: $white;
    border-radius: 100%;
    height: 18px;
    width: 18px;

    opacity: 1;
    text-align: center;
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}
/*
    Left-nav
*/
.left-nav {
    width: 15%;
    height: 100vh;
    order: 1;
    background: $color-subsidiary-darker;
    overflow-y: auto;
}

.left-nav-scroll {
    //  overflow-y: hidden;
    height: 100vh;
    position: relative;
}
.left-nav .logo {
    padding: 40px 0 40px;
    text-align: center;
}
.left-nav .website-select, .left-nav .language-select {
    margin: 0 25px;
}
.left-nav .website-select .css-1okebmr-indicatorSeparator, .left-nav .language-select .css-1okebmr-indicatorSeparator {
    display: none;
}
.left-nav .left-navigation-wrapper {
    padding-top: 30px;
}
.left-nav .left-navigation-wrapper .left-navigation {
    flex-direction: column;
}
.left-nav .left-navigation-wrapper .left-navigation .nav-item {
     position: relative;
     
     &.open > .nav-link {
          border-bottom: 1px solid #15252f;
     }
     
}
.left-nav .left-navigation-wrapper .left-navigation .nav-item .nav-link {
    padding: 15px 30px;
    color: $white;
}
.left-nav .left-navigation-wrapper .left-navigation .nav-item .nav-link svg {
    margin-right: 10px;
}
.left-nav .left-navigation-wrapper .left-navigation .nav-item .nav-link.active {
    background: $color-subsidiary-darkest;
    color: $color-primary;
    position: relative;
}
.left-nav .left-navigation-wrapper .left-navigation .nav-item .nav-link.active:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 5px;
    height: 100%;
    background: $color-primary;
}

.nav-item {
    &.open {
        background: $color-subsidiary-darkest; 
        .subnav-toggle {
              background-color: $color-subsidiary-darker;
        }
    }

    .subnav-toggle {
        position: absolute;
        color: $white;
        background-color: $color-subsidiary-darkest;
        border-radius: 4px;
       
        right: 10px;
        top: 10px;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        cursor: pointer;
        
        svg {
            margin-left: 0px;
            width: 10px;
        }
        
        &:hover {
            color: $white;
            background-color: $color-subsidiary;
        }
    }
}

.subnav {
    border-bottom: 1px solid $color-subsidiary-dark;
    display: none;
    .open & {
        display:block;
    }
}

.left-nav .left-navigation-wrapper .left-navigation .subnav-item .subnav-link {
    padding: 10px 30px;
    font-size: small;
    color: $white;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
.left-nav {
    scrollbar-width: auto;
    scrollbar-color: $color-primary $color-primary-dark;
}

/* Chrome, Edge, and Safari */
.left-nav::-webkit-scrollbar {
    width: 4px;
}

.left-nav::-webkit-scrollbar-track {
    background: $color-primary-dark;
}

.left-nav::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 10px;
    border: 2px solid $color-primary-dark;
}
/**
    Link tooltip
*/
.__react_component_tooltip.custom-tooltip {
    font-size: 13px!important;
    color: $white !important;
    background: $color-primary-dark!important;
    padding: 0 10px!important;
    border-radius: 5px!important;
    opacity: 1!important;
    height: unset!important;
}
.__react_component_tooltip.custom-tooltip span {
    font-size: 13px!important;
    color: $white !important;
    background: $color-primary-dark!important;
    padding: 0 10px!important;
    border-radius: 5px!important;
    opacity: 1!important;
    height: unset!important;
}
.custom-tooltip.place-top:after {
    border-top-color: $color-primary-dark !important;
}
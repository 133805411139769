/*
  Custom Form
*/
.custom-form {
}
.custom-form.media {
    width: 363px;
    margin: auto
}
.custom-form form {
}
.custom-form form .form-group {

    &.subsettings {
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: $spacing-small;
        padding-right: 10px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: $spacing-small;
        background: $white;
        border: 1px solid $color-subsidiary-lighter;
        border-top: none;
        border-radius: 0 0 5px 5px;
        margin-top: -6px;
        // z-index: 1;
        width: 100%;

        &.sub {
            border: 1px solid $color-subsidiary-lighter;
            background:$offwhite;
            padding: 5px 5px 2px;
        }
    }
}
.custom-form form .form-group .form-label {
    font-size: 15px;
    color: $color-primary-dark;
}
.custom-form form .form-group .form-label .optional {
    margin-left: 5px;
    font-size: 13px;
    color: $color-subsidiary-lighter;
    font-style: italic;
}
.custom-form form .form-group .form-label .extra {
    margin-left: 5px;
    font-size: 15px;
    color: $color-subsidiary-lighter;
}
.custom-form form .form-group .form-label.hidden {
    visibility: hidden;
}
.custom-form form .form-group .form-control {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    color: $color-primary-dark;
}
.custom-form form .form-group .form-control.is-invalid,
.custom-form form .form-group .react-tagsinput.is-invalid,
.custom-form form .form-group .react-select-container.is-invalid .react-select__control
{
  //  background: $color-delete-background !important;
    color: $color-invalid-border;
    border-color: $color-invalid-border !important;
}
.custom-form form .form-group .form-control.is-invalid::placeholder,
.custom-form form .form-group .react-select-container.is-invalid .react-select__control .react-select__value-container > *,
.custom-form form .form-group .react-select-container.is-invalid .react-select__control .react-select__indicators .react-select__indicator
{
    color: $color-invalid-border;
}
.custom-form form .form-group .react-select-container.is-invalid .react-select__control .react-select__indicators .react-select__indicator-separator {
    background: $color-invalid-border;
}
.custom-form form .form-group textarea.form-control {
    line-height: unset;
}
.custom-form form .form-group .form-control::placeholder {
    color: $color-subsidiary-lighter;
    font-size: 13px;
}
.custom-form form .form-group .btn {
    margin-top: 25px;
}
.custom-form form .form-group .profile-picture-placeholder {
    height: 170px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $white;
    box-shadow: $boxshadow !important;
}
.custom-form form .form-group .profile-picture-placeholder img {
    height: 170px;
    width: 100%;
    object-fit: cover;
}
.custom-form form .form-group .profile-picture-placeholder svg {
    font-size: 35px;
    width: 100%;
    color: $color-subsidiary-lighter;
    margin: 10px 0;
}
.custom-form form .form-group .profile-picture-placeholder p {
    color: $color-subsidiary-lighter;
    font-size: 13px;
    padding: 0 20px;
}
.custom-form form .form-group {
    position: relative
}
.custom-form form .form-group svg.password-toggle {
    position: absolute;
    right: 15px;
    cursor: pointer;
    margin-top: 44.5px;
    top: 0;
}
.custom-form form .media-or {
    text-align: center;
}
.custom-form form .media-or p {
    text-transform: uppercase;
}
.custom-form form .form-group .media-url-wrapper {
    background: $color-primary-lightest;
    padding: 20px 35px 15px;
}
.custom-form form .form-group .media-url-wrapper h1 {
    font-size: 25px;
    text-align: center;
    color: $color-primary-dark;
}
.custom-form form .form-group .media-url-wrapper p {
    color: $color-primary-dark;
    font-size: 12px;
    text-align: center;
    margin: 5px 0 0 0;
}
.custom-form form .form-group .api-key {
    padding: 0 30px;
    height: 50px;
    background: $white;
    display: flex;
    box-shadow: $boxshadow !important;
}
.custom-form form .form-group .api-key .form-control {
    font-size: 14px;
    color: $color-primary-dark;
    border-radius: 0;
    border: 0;
    padding: 0;
    height: 50px;
    line-height: 50px;
}
.custom-form form .form-group .api-key .form-control::placeholder {
    color: $color-primary-dark;
}
.custom-form form .form-group .api-key .btn {
    background: transparent;
    color: $color-primary-dark;
    border: 0;
    border-radius: 0;
    box-shadow: none!important;
}
.custom-form form .form-group .api-key .btn svg {
}
.custom-form form .form-group .input-info {
    color: $color-subsidiary-lighter;
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 0px;

    &:first-child {
        margin-top: 10px;
    }

    &:last-child {
        margin-bottom: 10px;
        margin-top: 5px;
    }
}
.custom-form form .form-group .input-group-text {
    background: $color-primary-lightest;
    color: $color-primary-dark;
    border-color: $color-subsidiary-lighter;
}
.custom-form form .form-group .actions {
    display: flex;
}
.custom-form form .form-group .actions .btn {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
}
.custom-form form .form-group .input-group > .form-control:last-of-type {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.custom-form .progress-wrapper {
    width: 100%;
}
.custom-form .progress-wrapper p {
    text-align: center;
    font-size: 26px;
}
.custom-form .progress-wrapper .progress {
    background-color: $color-primary-lightest;
    margin-bottom: 20px;
}
.custom-form .progress-wrapper .progress .progress-bar {
    background-color: $color-primary;
}
.custom-form.website-translation form .buttons,
.custom-form.website-setting form .buttons {
    margin: 0;
}
.custom-form.website-translation form .buttons .form-group .btn,
.custom-form.website-setting form .buttons .form-group .btn {
    width: 100%;
}
.custom-form.website-translation form .buttons .form-group .btn svg,
.custom-form.website-setting form .buttons .form-group .btn svg {
    margin-right: 5px;
}
.custom-form .react-select__menu {
    z-index: 999999;
}

/* Custom form - settings */
.custom-form form .form-group.custom-settings {
}

.custom-toggle-wrap {
    margin-bottom: $spacing*2;
}

.custom-form form .form-group.custom-settings .custom-toggle {
    background: $white;
    border: 1px solid $color-subsidiary-lighter;
    //$color-border;
    position: relative;

    font-family: "Source Sans Pro";
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: $color-primary-dark;

    padding: $spacing;


    &:after {
        position: absolute;
        right: $spacing;
        top: 12px;
        font-family: "Font Awesome 5 Free";
        font-weight: 700;
        content: "\f078";
        color: $color-text-light;
        font-size: 0.8rem;

        background: $color-border;
        width: 28px;
        height: 28px;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.active {
        background: $color-primary-dark;
        border: 1px solid $color-primary-dark;
        color: $white;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        margin-bottom: $spacing;

        &:after {
            content: '\f077';
            background: rgba($white, 0.2);
            color: $white;

        }
    }
}


.custom-form form .form-group.custom-settings .form-group {
}
.custom-form form .form-group.custom-settings .form-group .input-group {
    position: relative;
    max-width: 100%;
    
   


    flex-wrap: nowrap;
    
    textarea {
        min-height: 70px;
    }

}
.custom-form form .form-group.custom-settings .form-group .input-group:after {
    content: 'px';
    position: absolute;
    font-size: 13px;
    color: $color-subsidiary-lighter;
    right: 15px;
    top: 10px;
    z-index: 3;
}
.custom-form form .form-group.custom-settings .form-group .input-group.no-suffix:after {
    content: '';
    position: absolute;
    font-size: 13px;
    color: $color-subsidiary-lighter;
    right: 15px;
    top: 10px;
    z-index: 3;
}
.custom-form form .form-group.custom-settings .form-group .input-group.large:after,
.custom-form form .form-group.custom-settings .form-group .input-group.xl:after,
.custom-form form .form-group.custom-settings .form-group .input-group.xxl:after {
    content: '';
    position: absolute;
    font-size: 13px;
    color: $color-subsidiary-lighter;
    right: 15px;
    top: 10px;
    z-index: 3;
}
.custom-form form .form-group.custom-settings .form-group .input-group .input-group-prepend {
    width: calc(100% - 120px);

    width:auto;
  // min-width: 60px;
}
.custom-form form .form-group.custom-settings .form-group .input-group.large .input-group-prepend {
    width: calc(100% - 360px);

    //width:auto;
}
.custom-form form .form-group.custom-settings .form-group .input-group.xl .input-group-prepend {
    width: calc(100% - 480px);

    //width:auto;
}
.custom-form form .form-group.custom-settings .form-group .input-group.xxl .input-group-prepend {
    width: calc(100% - 640px);

    // width:auto;

}
.custom-form form .form-group.custom-settings .form-group .input-group .input-group-prepend .input-group-text {
    width: 100%;
    font-weight: 600;
    color: $color-primary-dark;
    background: $white;
    
    


}
.ig-textarea .input-group-text {
        align-items: flex-start !important;
        padding-top:10px;
 }
    
.custom-form form .form-group.custom-settings .form-group .input-group .form-control {
    width: 120px;
    flex: unset;
    padding-right: 35px;
 min-width: 250px;
    width: 100%;
}
.custom-form form .form-group.custom-settings .form-group .input-group.large .form-control {
    width: 240px;
    flex: unset;
    padding-right: 35px;

    width: 100%;

    &-xs {
        padding-right: 0;

        width: auto;
        min-width: 60px;
    }
}
.custom-form form .form-group.custom-settings .form-group .input-group.xl .form-control {
    width: 480px;
    flex: unset;
    padding-right: 35px;

    width: 100%;
}
.custom-form form .form-group.custom-settings .form-group .input-group.xxl .form-control {
    width: 640px;
    flex: unset;
    padding-right: 35px;
    //  @include mq(0,xl) {
    width: 100%;
    // }
}
.custom-form form .form-group.custom-settings .form-group .custom-switch {
    // height: 40px;
    height: auto;
    line-height: 40px;
    border: 1px solid $color-subsidiary-lighter;
    background: $white;
    border-radius: 0.25rem;
    padding: 0 0.75rem;

    .form-label {
        margin-bottom: 0;
    }
}
.custom-form form .form-group.custom-settings .form-group .custom-switch .custom-control-label {
    width: 100%;
    line-height: 18px;
    font-weight: 600;
    color: $color-primary-dark;
    padding-top: 10px;
    padding-bottom:10px;
    padding-right: 50px;
}
.custom-form form .form-group.custom-settings .form-group .custom-switch .custom-control-label::before {
    left: unset;
    right: 0;
    width: 45px;
    height: 26px;
    top: 6px;
    border-radius: 13px;
    background: $color-subsidiary-lighter;
    border-color: $color-subsidiary-lighter;
    box-shadow: none!important;
}
.custom-form form .form-group.custom-settings .form-group .custom-switch .custom-control-label::after {
    top: 8px;
    left: unset;
    right: 20px;
    height: 20px;
    width: 20px;
    border-radius: 13px;
    background: $white;
    box-shadow: 0px 3px 6px 0px rgba($black, 0.18);
}
.custom-form .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
    background: $color-primary !important;
    border-color: $color-primary !important;
}
.custom-form form .form-group.custom-settings .form-group .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(15px);
}
.custom-form form .form-group.custom-settings .form-group .input-info {
    margin: 0 0 5px 0;
}

.invalid-feedback {
    > p:last-child {
        margin-bottom: 0;
    }
}

.was-validated .react-select-container:invalid ~ .invalid-feedback,
.was-validated .react-select-container:invalid ~ .invalid-tooltip,
.react-select-container.is-invalid ~ .invalid-feedback,
.react-select-container.is-invalid ~ .invalid-tooltip {
    display: block;
}
.react-select-container.is-invalid .react-select__control {
    border-color: $color-delete;
}
.react-select-container .react-select__single-value {
    color: $color-primary-dark;
}

.custom-form.media.eye-catcher {
    width: 100%;
}
.custom-form.media.eye-catcher .placeholder-image {
    height: 140px;
    float: left;
    width: 140px;
    margin-right: 20px;
    background: $white;
    box-shadow: 0px 2px 5px 0px rgb(0 0 10 / 10%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.custom-form.media.eye-catcher .placeholder-image img {
    padding: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
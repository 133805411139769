
/**
    Page edit upload
*/
.page-edit-upload {
    margin-top: 25px;
    background: $white;
    -webkit-box-shadow: 0 0 6px 2px rgba($black, 0.16);
    -moz-box-shadow: 0 0 6px 2px rgba($black, 0.16);
    box-shadow: 0 0 6px 2px rgba($black, 0.16);
}
.page-edit-upload .header {
    display: flex;
    height: 46px;
    padding: 0 20px;
    align-items: center;
}
.page-edit-upload .header .title {
    color: $color-primary-dark;
    margin: 0 30px 0 0;
    line-height: 46px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
}
.page-edit-upload .header .search-wrapper {
    display: flex;
    align-items: center;
}
.page-edit-upload .header .search-wrapper svg {
    color: $color-primary-dark;
    margin-right: 10px;
}
.page-edit-upload .header .search-wrapper .form-control {
    font-size: 15px;
    padding: 0;
    height: fit-content;
    line-height: unset;
    border-radius: unset;
    border-color: transparent;
}
.page-edit-upload .header button {
    border-color: transparent;
    background: transparent;
}
.page-edit-upload .header .upload svg {
    color: $color-primary-dark;
    margin-right: 10px;
}
.page-edit-upload .header .toggle {
    background: $offwhite;
    height: 28px;
    line-height: 28px;
    width: 28px;
    border-radius: 100%;
    text-align: center;
}
.page-edit-upload .header .toggle svg {
    color: $color-subsidiary-lighter;
}
.page-edit-upload .files-content .react-bootstrap-table .table {
    border: none;
}
.page-edit-upload .files-content .react-bootstrap-table .table thead {
    background: $color-primary-lightest;
}
.page-edit-upload .files-content .react-bootstrap-table .table thead tr {
    height: 34px;
}
.page-edit-upload .files-content .react-bootstrap-table .table thead tr th {
    border: none;
    padding: 0;
    line-height: 34px;
    font-size: 13px;
    color: $color-primary-dark;
}
.page-edit-upload .files-content .react-bootstrap-table .table thead tr th:first-child {
    width: 50px;
}
.page-edit-upload .files-content .react-bootstrap-table .table tbody {
}
.page-edit-upload .files-content .react-bootstrap-table .table tbody tr {
    height: 30px;
    cursor: move;
}
.page-edit-upload .files-content .react-bootstrap-table .table tbody tr td {
    padding: 0;
    font-size: 14px;
    line-height: 30px;
    border: 0;
}
.page-edit-upload .files-content .react-bootstrap-table .table tbody tr:nth-child(even) {
    background: $offwhite;
}
.page-edit-upload .files-content .react-bootstrap-table .table tbody tr td:first-child svg {
    transform: rotate(45deg);
    color: $color-primary-dark;
    margin-left: 10px;
}
/*
  Card
*/
.card {
    padding: 0 15px;
    border: 0;
    -webkit-box-shadow: 0 0 6px 2px rgba($black,0.16);
    -moz-box-shadow: 0 0 6px 2px rgba($black,0.16);
    box-shadow: 0 0 6px 2px rgba($black,0.16);
}
.card .card-header {
    background: $white;
    border: 0;
}
.card .card-header .logo {
    padding: 20px 0 0;
}
.card .card-header .lang-switch ul {
    justify-content: flex-end;
    padding-top: 12px;
}
.card .card-body {
}
.card .card-body a {
    font-size: 13px;
    color: $color-subsidiary-lighter;
    text-decoration: underline;
}
.card .card-body .btn {
    font-size: 18px;
    float: right;
    padding: 10px 35px;
}

/*
  Card green
*/
.card.green .card-header {
    background: $color-primary-lightest;
    margin: 0 -15px;
    padding: 20px 35px;
}
.card.green .card-header h1 {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
    color: $color-primary-light;
}
.card.green .card-body label {
    font-size: 15px;
    color: $color-primary-dark;
}


/*
  Card CMS
*/
.card.cms .card-body label {
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 30px;
}
.card.cms .card-body .form-control {
    border-color: transparent;
    height: 30px;
    font-size: 15px;
    color: $color-primary-dark;
}
.card.cms .card-body .form-control[readonly] {
    background: unset;
    color: $color-primary-dark;
}
.card.cms .card-body .form-control:hover,
.card.cms .card-body .form-control:focus  {
    -webkit-box-shadow: 0px 3px 6px 0px rgba($black,0.08)!important;
    -moz-box-shadow: 0px 3px 6px 0px rgba($black,0.08)!important;
    box-shadow: 0px 3px 6px 0px rgba($black,0.08)!important;
}
.card.cms .card-body .form-control.is-invalid {
    background: $color-invalid-background;
    border-color: $color-invalid-border;
    color: $color-invalid-border;
}
.card.cms .card-body .react-select-container {
    height: 30px;
}
.card.cms .card-body .react-select-container .react-select__control {
    border-color: transparent;
    min-height: 0;
    height: 30px;
    padding: 0.375rem 0.75rem;
}
.card.cms .card-body .react-select-container .react-select__control .react-select__value-container {
    padding: 0;
    height: 100%;
}
.card.cms .card-body .react-select-container .react-select__control .react-select__value-container .react-select__single-value {
    font-size: 15px;
    color: $color-primary-dark;
    margin: 0;
}
.card.cms .card-body .react-select-container.react-select-container-fix .react-select__control .react-select__value-container .react-select__single-value {
    position: absolute;
    transform: none;
    top: 0;
    left: 0;
}
.card.cms .card-body .react-select-container .react-select__control .react-select__indicators {
    display: none;
}
.card.cms .card-body .react-select-container .react-select__control .react-select__input input {
    height: 100%;
    font-size: 15px;
    color: $color-primary-dark;
}
.card.cms .card-body form .btn {
    padding: 5px 25px;
}
.card.cms.submit .card-body .btn {
    width: 100%;
    font-size: 16px;
    padding: 10px 15px;
    margin-top: -30px;
}

/*
  Activate 2fa
*/
.card.activate-2fa {
    width: 570px;
    position: relative;
    left: -100px;
}
.card.activate-2fa .card-body {
    padding: 2.25rem 1.25rem;
}
.card.activate-2fa .step-title {
    font-size: 20px;
    color: $color-subsidiary-darker;
    text-align: center;
    margin-top: 25px;
}
.card.activate-2fa p {
    font-size: 15px;
    text-align: center;
    color: $color-subsidiary-darker;
    font-weight: 300;
}
.card.activate-2fa .qr-code {
    display: block;
    margin: auto;
}
.card.activate-2fa .secret {
    background: $offwhite;
    width: 55%;
    margin: 10px auto 20px auto;
    height: 51px;
    line-height: 51px;
}
.card.activate-2fa .secret p {
    color: $color-primary-dark;
}
.card.activate-2fa .secret p span {
    padding: 0 2px;
}
.card.activate-2fa .secret p span:first-child {
    padding-left: 0;
}
.card.activate-2fa .secret p span:nth-child(4) {
    padding-right: 0;
}
.card.activate-2fa .secret p a {
    margin-left: 20px;
    color: $color-primary-dark;
}
.card.activate-2fa .secret p a:hover {
    color: $color-subsidiary-lighter;
}
.card.activate-2fa .buttons {
    display: flex;
    justify-content: space-between;
    width: 65%;
    margin: 40px auto 0!important;
}
.card.activate-2fa .buttons .btn {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    width: 100%;
    justify-content: center;
}
.card.activate-2fa .buttons .btn:first-child {
    margin-right: 7px;
}
.card.activate-2fa .buttons .btn:last-child {
    margin-left: 7px;
}
.card.activate-2fa form {
    width: 70%;
    margin: auto;
}
.card.activate-2fa form .form-group {
    margin: 20px 15px;
}
.card.activate-2fa form .buttons {
    width: 100%;
}
.card.activate-2fa form .buttons.form-group {
    margin: 0;
}
.card.activate-2fa.passcode form .form-group .form-label {
    text-align: center;
    margin-bottom: 30px;
    width: 100%;
}
.card.activate-2fa.passcode form .form-check {
    text-align: center;
}
.card.activate-2fa.passcode form {
    width: 80%;
}

/**
  Backup codes
*/
.card.activate-2fa .backup-codes {
}
.card.activate-2fa .backup-codes > p {
    color: $color-primary-dark;
    font-size: 15px;
    text-align: center;
}
.card.activate-2fa .backup-codes .backup-codes-wrapper {
    display: flex;
    flex-wrap: wrap;
}
.card.activate-2fa .backup-codes .backup-codes-wrapper .backup-code {
    width: calc(50% - 40px);
    text-align: center;
    background: $offwhite;
    font-size: 14px;
    color: $color-primary-dark;
    margin: 0 20px 15px;
    height: 26px;
    line-height: 26px;
}
.card.activate-2fa .backup-codes .buttons {
    width: 100%;
    justify-content: center;
}
.card.activate-2fa .backup-codes .buttons .btn {
    margin: 0 5px;
    padding: 10px 16px;
    width: fit-content;
}
.card.activate-2fa .backup-codes .buttons .btn svg {
    margin-right: 5px;
}

/*
  Forgot password
*/
.card.forgot-password .card-body .btn {
    width: 100%;
    padding: 10px 15px;
    font-size: 16px;
    margin-top: 20px;
}
.card.forgot-password .card-body .form-control.is-invalid {
    background: $color-invalid-background;
    border-color: $color-invalid-border;
    color: $color-invalid-border;
}
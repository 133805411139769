

/*
  Login
*/
form#login-form .form-group svg.password-toggle {
    position: absolute;
    right: 15px;
    cursor: pointer;
    margin-top: 12.5px;
    top: 0;
    color: $color-subsidiary-lighter
}

form#password-reset-form .form-group svg.password-toggle {
    position: absolute;
    right: 15px;
    cursor: pointer;
    margin-top: 12.5px;
    top: 0;
    color: $color-subsidiary-lighter
}

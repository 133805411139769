/**
    Custom images table
*/
.custom-images-table {
    background: $color-primary-lightest;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    @include mq(md) {
         padding: 50px;
    }
}
.custom-images-table .custom-images-table-item {
    width: 100%;
    position: relative;
    padding: 20px 0;
    
    @include mq(md) {
         width: 50%;
    }

    @include mq(lg) {
        width: calc(100%/3);

    }
     @include mq(xl) {
        width: calc(100%/4);

    }
     @include mq(xxl) {
        width: calc(100%/6);

    }
}
.custom-images-table .custom-images-table-item .custom-images-table-item-inner {
    position: relative;
    width: 155px;
    height: 108px;
    margin: auto
}
.custom-images-table .custom-images-table-item .image {
    height: 100%;
    width: 100%;
}
.custom-images-table .custom-images-table-item .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.custom-images-table .custom-images-table-item .icon {
    position: absolute;
    height: 26px;
    width: 26px;
    //line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;

    top: -14px;
    text-align: center;
    font-size: 13px;
    border: 2px solid;
    border-radius: 100%;
}
.custom-images-table .custom-images-table-item .edit {
    right: 42px;
    background: $color-subsidiary-lightest;
    border-color: $color-edit;
    color: $color-edit;
}
.custom-images-table .custom-images-table-item .delete {
    right: 10px;
    background: $color-delete-background;
    border-color: $color-invalid-border;
    color: $color-invalid-border;
}
.custom-images-table .custom-images-table-item .info-icon {
    position: absolute;
    font-size: 13px;
    color: $color-primary-light;
    bottom: 10px;
    right: 10px;
}
.custom-images-table .custom-images-table-item .info-tooltip {
    z-index: 9999;
    position: absolute;
    display: none;
    background: $color-primary-light;
    color: $white;
    padding: 5px 20px 5px 10px;
    width: 100%;
   // max-width: 100%;
    left: 100%;
    margin-left: 5px;
    margin-top: -43px;
    border-radius: 3px;
    -webkit-box-shadow: 0px 2px 6px 0px rgba($black,0.1);
    -moz-box-shadow: 0px 2px 6px 0px rgba($black,0.1);
    box-shadow: 0px 2px 6px 0px rgba($black,0.1);
    @include mq(md) {
        width: 320px;
    }

    p {
        margin-bottom: 0.5rem;
        line-height: 1.2;
    }
}
.custom-images-table .custom-images-table-item .info-tooltip:before {
    content: '';
    border-style: solid;
    border-width: 8px 8px 8px 0;
    border-color: transparent $color-primary-light transparent transparent;
    position: absolute;
    left: -8px;
    top: 15px;
}
.custom-images-table .custom-images-table-item .info-tooltip p.title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}
.custom-images-table .custom-images-table-item .info-tooltip .text p, .custom-images-table .custom-images-table-item .info-tooltip .text a {
    font-size: 13px;
}
.custom-images-table .custom-images-table-item .info-tooltip.show {
    display: block;
}
.custom-images-table .custom-images-table-item .info-tooltip .link {
    font-size: 13px;
    color: $white;
}
.custom-images-table .custom-images-table-item .info-tooltip .close {
    font-size: 10px;
    color: $color-primary-light;
    background: $white;
    border-radius: 100%;
    height: 18px;
    width: 18px;
    line-height: 20px;
    opacity: 1;
    text-align: center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 8px;
    right: 8px;
}
.custom-images-table .custom-images-table-item.custom-images-table-item-add .custom-images-table-item-inner {
    background: $white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    -webkit-box-shadow: 0px 6px 6px 0px rgba(46,74,117,0.1);
    -moz-box-shadow: 0px 6px 6px 0px rgba(46,74,117,0.1);
    box-shadow: 0px 6px 6px 0px rgba(46,74,117,0.1);
}
.custom-images-table .custom-images-table-item.custom-images-table-item-add .custom-images-table-item-inner:hover,
.custom-images-table .custom-images-table-item.custom-images-table-item-add .custom-images-table-item-inner:focus {
    cursor: pointer;
    -webkit-box-shadow: 0px 6px 6px 0px rgb(0, 0, 15);
    -moz-box-shadow: 0px 6px 6px 0px rgba(46,74,117,0.2);
    box-shadow: 0px 6px 6px 0px rgba(46,74,117,0.2);
}
.custom-images-table .custom-images-table-item.custom-images-table-item-add .custom-images-table-item-inner svg {
    color: $color-primary-light;
    font-size: 35px;
    margin: 0 auto 7px;
}
.custom-images-table .custom-images-table-item.custom-images-table-item-add .custom-images-table-item-inner p {
    margin-bottom: 0;
    color: $color-primary-light;
    font-size: 13px;
    text-decoration: underline;
}
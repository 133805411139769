.fbgroup {
    position: relative;
    padding: $spacing ($spacing-larger - 4px) 0;
    background: $white;
    box-shadow: $boxshadow;
    margin-bottom: $spacing;
    border: 1px solid transparent;
    background-color: darken($white, 1%);

    .fbgroup {
        box-shadow: none !important;
        background-color: transparent;
        border: 1px solid $color-subsidiary-lightest;
    }

    &.is-selected {
        border-color: $color-primary;
        box-shadow: $boxshadow-active;
        background-color: $white;
    }

    &.sortable-ghost {
        border: 2px dashed $color-primary-light !important;
        background-color: $color-primary-lightest !important;
    }
    
    > .fbgroup-header {
        background: $color-primary-lightest;
        margin-left: -26px;
        margin-right: -26px;
    }
    
    .accordion {
        margin-left: $spacing-large;
        
        .accordion {
            margin-left: 0;
        }
    }
    
    .alert {
       /* padding: 0 !important;
        border: 0;
        background: none; */
        
    }
    
    .alert-danger {
         background: $color-delete-background;
    border-color: $color-delete-border;
    color: $color-delete;
        font-size: 13px;
    }
}

.fbgroup-head {
    margin-bottom: 0;
    width: 100%;
    
    
}

.fbgroup-body {
    //margin-left: -$spacing;
    margin-top:$spacing-small;
    margin-bottom:$spacing-small;
    
    .fbgroup-body {
        margin-left:0;
    }
}

.fbgroup-header {
    margin-left: -58px;
    margin-right: -$spacing-larger;
    margin-top: -$spacing;
    padding-top: $spacing;
    padding-left: $spacing;
    padding-right: $spacing;
    padding-bottom: $spacing-small;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: $spacing;
}

.fbgroup-title {
    font-size: 14px;

    .fbgroup .fbgroup & {
        display: inline-block;
        color: $color-primary-light;
        position: relative;
        top: -1px;
        font-weight: 600;
    }
}

.fbgroup-handle {
    padding: 0.375rem 0.75rem;
    color: $color-primary-light;
}

.fbgroup-fieldhandle,
.fbgroup-optionhandle {
    padding: 0.125rem 0.25rem;
    color: $color-primary-light;
}

.fbgroup-actions {
    margin-left: auto;
    align-self: stretch;
    display: flex;
    align-items: center;

    .fbgroup .fbgroup & {
        align-self: center;
    }
}

.fbgroup-action {
    
}

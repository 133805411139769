
/**
    Custom text editor
*/
.custom-text-editor .fr-toolbar.fr-top {
    border-color: $color-subsidiary-lighter;
    border-radius: 0.25rem 0.25rem 0 0;
}
.custom-text-editor .fr-box.fr-basic.fr-top .fr-wrapper {
    border-color: $color-subsidiary-lighter;
    border-radius: 0 0 0.25rem 0.25rem;
}
.custom-text-editor .fr-toolbar .fr-btn-grp {
    margin: 0;
}
.custom-text-editor .fr-toolbar .fr-newline {
    background: $color-subsidiary-lighter;
    margin: 0;
}

.custom-text-editor .is-invalid .fr-toolbar.fr-top {
    border-color: $color-invalid-border;
    background: $color-invalid-background;
}
.custom-text-editor .is-invalid .fr-box.fr-basic.fr-top .fr-wrapper {
    border-color: $color-invalid-border;
    background: $color-invalid-background;
    border-bottom: 1px solid $color-subsidiary-lighter;
    border-radius: 0;
}

.custom-text-editor .is-invalid .fr-second-toolbar {
    border-color: $color-invalid-border;
    background: $color-invalid-background;
}
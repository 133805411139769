.btn {
    text-decoration: none !important;
    transition: 0.2s;
    font-size: 0.9rem;
}

.btn-disabled {
    pointer-events: none;
    opacity: .5;
}

.btn.btn-primary {
    background: $color-primary;
    border-color: $color-primary;
}

.btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:not(:disabled):not(.disabled):active {
    background: $white;
    color: $color-primary;
    border-color: $color-primary;
}

.btn.btn-primary.disabled {
    pointer-events: none;
}

.btn.btn-outline-primary {
    border-color: $color-primary;
    color: $color-primary;
}

.btn.btn-outline-primary:hover, .btn.btn-outline-primary:focus, .btn.btn-outline-primary:not(:disabled):not(.disabled):active {
    border-color: $color-primary;
    color: $white;
    background: $color-primary;
}

.btn.btn-secondary {
    background: $color-subsidiary-lighter;
    color: $white;
    border-color: $color-subsidiary-lighter;
}

.btn.btn-secondary:hover, .btn.btn-secondary:focus, .btn.btn-secondary:not(:disabled):not(.disabled):active {
    background: $white;
    color: $color-subsidiary-lighter;
    border-color: $color-subsidiary-lighter;
}

.btn.btn-link {
    color: $color-primary-light;
    text-decoration: underline;

    &:hover {
        color: $color-primary-dark;
    }

    &.btn-link-bold {
        font-weight: 600;
    }
    
    &:focus {
            box-shadow: none !important;
        }

    &:not(:disabled):not(.disabled):active {
        border-color: transparent;
        background-color:transparent;

        
    }
}

.btn-settings {
    position: absolute;
    top: 0;
    right: 30px;
    background: $color-subsidiary-darkest;
    color: $color-primary-light;
    padding: 5px 8px;
    border-radius: 0 0 8px 8px;

    &:hover {
        background: $color-primary;
        color: $white;
    }
}

.buttons-wrap {
    @include mq(0, lg) {
        padding: 0 10px;
    }
}

.iconbtn {
    font-size: 13px;
    height: 28px;
    width: 28px;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
    border-radius: 100%;
    border: 1px solid currentColor;

    &.btn-sm {
        font-size: 11px;
        height: 21px;
        width: 21px;
    }

    & + & {
        margin-left: 4px;
    }

    &.btn-active, &.btn-primary {
        border-color: $color-active;
        background-color: $color-active-background;
        color: $color-active;

        &:hover, &:focus {
            border-color: $color-active;
            background-color: $color-active;
            color: $white;
        }

        &:not(:disabled):not(.disabled):active {
            border-color: $color-active;
            background-color: $color-active;
            color: $white;

            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($color-active, 0.5);
            }
        }
    }

    &.btn-inactive, &.btn-warning {
        border-color: $color-inactive;
        background-color: $color-inactive-background;
        color: $color-inactive;

        &:hover, &:focus {
            border-color: $color-inactive;
            background-color: $color-inactive;
            color: $white;
        }

        &:not(:disabled):not(.disabled):active {
            border-color: $color-inactive;
            background-color: $color-inactive;
            color: $white;

            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($color-inactive, 0.5);
            }
        }
    }

    &.btn-edit, &.btn-info {
        border-color: $color-edit;
        background-color: $color-edit-background;
        color: $color-edit;

        &:hover, &:focus {
            border-color: $color-edit;
            background-color: $color-edit;
            color: $white;
        }

        &:not(:disabled):not(.disabled):active {
            border-color: $color-edit;
            background-color: $color-edit;
            color: $white;

            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($color-edit, 0.5);
            }
        }
    }

    &.btn-invalid, &.btn-danger {
        border-color: $color-invalid-border;
        background-color: $color-invalid-background;
        color: $color-invalid;

        &:hover, &:focus {
            border-color: $color-invalid;
            background-color: $color-invalid;
            color: $white;
        }

        &:not(:disabled):not(.disabled):active {
            border-color: $color-invalid;
            background-color: $color-invalid;
            color: $white;

            &:focus {
                box-shadow: 0 0 0 0.2rem rgba($color-invalid, 0.5);
            }
        }
    }
}

.fr-popup.fr-active {
    z-index: 999999 !important;
}

.fr-emoticon {
  background-repeat: no-repeat !important;
    font-size: inherit;
    height: 1em;
    width: 1em;
    min-height: 20px;
    min-width: 20px;
    display: inline-block;
    margin: -.1em .1em .1em;
    line-height: 1;
    vertical-align: middle;
}
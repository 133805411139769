.actions {
    display: flex;

    svg {
        color: $color-primary-light !important;
    }
    svg.danger {
        color: $color-invalid !important;
    }
    
    svg {
        &:hover {
            color: $color-primary-dark !important;
        }
    }

    .active {
        border: 1px solid $color-active !important;
        background: $color-active-background !important;

        svg {
            color: $color-active !important;
        }

        &:hover {
            background: $color-active !important;

            svg {
                color: $color-active-background !important;
            }
        }
    }

    .inactive {
        border: 1px solid $color-inactive !important;
        background: $color-inactive-background !important;

        svg {
            color: $color-inactive !important;
        }

        &:hover {
            background: $color-inactive !important;

            svg {
                color: $color-inactive-background !important;
            }
        }
    }

    .edit {
        border: 1px solid $color-edit !important;
        background: $color-edit-background !important;

        svg {
            color: $color-edit !important;
        }

        &:hover {
            background: $color-edit !important;

            svg {
                color: $color-edit-background !important;
            }
        }
    }

    .delete {
        border: 1px solid $color-delete !important;
        background: $color-delete-background !important;

        svg {
            color: $color-delete !important;
        }

        &:hover {
            background: $color-delete !important;

            svg {
                color: $color-delete-background !important;
            }
        }
    }

    .more-actions {
        border: 1px solid $gray-lightest !important;
        background: $white !important;

        svg {
            color: $black !important;
        }

        &:hover {
            background: $gray-lightest !important;

            svg {
                color: $white !important;
            }
        }
    }

    .link {
        background: $green-lightest !important;
        border-radius: 6px !important;
        padding: 0 20px !important;

        color: $green-light !important;

        text-decoration: none !important;

        svg {
            color: $green-light !important;
        }

        &:hover {
            background: $green-light !important;
            color: $green-lightest !important;

            svg {
                color: $green-lightest !important;
            }
        }
    }

    .default {
        svg {
            color: $color-primary-light !important;
        }

        &:hover {
            svg {
                color: $color-primary-dark !important;
            }
        }
    }

    .disabled {
        pointer-events: none;
        color: $gray-lighter !important;

        &:hover {
            color: $gray-lighter !important;
        }
    }
}



/*.rst__nodeCo*/
.rst__tree {
}
.rst__tree .ReactVirtualized__Grid {
}
.rst__tree .ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer {
}
.rst__tree .ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer .rst__node{
}
.rst__tree .ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer .rst__node .rst__lineBlock {
}
.rst__tree .ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer .rst__node .rst__nodeContent {
}
.rst__tree .ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer .rst__node .rst__nodeContent .rst__rowWrapper {
}
.rst__tree .ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer .rst__node .rst__nodeContent .rst__rowWrapper .rst__row {
}
.rst__tree .ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__moveHandle {
    background: $color-primary-lightest;
    box-shadow: none;
    position: relative;
}
.rst__tree .ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__moveHandle:before {
  /*  font-family: "Font Awesome 5 Free";
    font-weight: 900; */
    content: "\2725";
    transform: rotate(45deg) translate(-85%, -15%);
    position: absolute;
    left: 50%;
    top: 50%;
}
.rst__tree .ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents {
    background: $offwhite;
    box-shadow: none;
}
.rst__tree .ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowLabel {
    font-size: 14px;
}
.rst__tree .ReactVirtualized__Grid .ReactVirtualized__Grid__innerScrollContainer .rst__node .rst__nodeContent .rst__rowWrapper .rst__row .rst__rowContents .rst__rowLabel .rst__rowTitle {
    font-weight: normal;
    color: $color-primary-dark;
}

.rst__rowWrapper {
    padding: 0 !important;
}

.rst__moveHandle {
    border: none !important;
    background: none !important;
}

.rst__rowContents {
    border: none !important;
    background: $white !important;
}

.rst__row {
    border: 1px solid $color-border !important;
}

.rst__nodeContent {
    right: 0;
}

.rst__lineHalfHorizontalRight::before, 
.rst__lineFullVertical::after, 
.rst__lineHalfVerticalTop::after, 
.rst__lineHalfVerticalBottom::after {
    background: darken($color-border, 20%) !important;
}

.rst__lineChildren::after {
    display: none !important;
}

.rst__collapseButton, .rst__expandButton {
    box-shadow: 0 0 0 1px darken($color-border, 20%) !important;
    
    &:focus {
        box-shadow: 0 0 0 1px darken($color-border, 20%), 0 0 1px 3px $color-primary !important;
    }
}
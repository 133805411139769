

/*
  Edit profile
*/
.edit-profile {
    box-shadow: 0 0 6px 2px rgba($black ,0.16);
    border-radius: 0.25rem;
}
.edit-profile .card {
    box-shadow: none;
    border-radius: 0;
}
.edit-profile > .card:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    display: flex;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 8px;
}
.edit-profile > .card:first-child .name{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.edit-profile > .card:first-child h1 {
    font-size: 18px;
    color: $color-primary-dark;
    margin: 0;
}
.edit-profile > .card:first-child h1 span.language {
    font-size: 14px;
    height: 23px;
    padding: 0 2px;
    min-width: 23px;
    line-height: 23px;
    text-align: center;
    background: $color-primary-lightest;
    color: $color-primary-dark;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 5px;
    margin-left: 20px;
}
.edit-profile > .card:first-child h1 span.language:hover, .edit-profile > .card:first-child h1 span.language:focus {
    cursor: pointer;
}
.edit-profile > .card:first-child h2 {
    font-size: 15px;
    color: $color-subsidiary-lighter;
}
.edit-profile > .card:first-child h1 .react-select-container {
    display: inline-block;
    margin-left: 10px;
}
.edit-profile > .card:first-child h1 .react-select-container .react-select__control {
    min-height: 28px;
    height: 28px;
    width: 150px;
    font-size: 14px;
}
.edit-profile > .card:first-child h1 .react-select-container .react-select__menu .react-select__menu-list .react-select__option {
    padding: 4px 6px;
    font-size: 14px;
}
.edit-profile > .card:first-child h1 .react-select-container .react-select__control .react-select__indicators .react-select__indicator svg {
    height: 10px;
    width: 10px;
}

.form-control {
    height: 45px;
    border-color: $color-subsidiary-lighter;
    box-shadow: none!important;

    &:focus {
        border-color: $color-primary-light;
        border-width:2px;
    }
}

.form-control-sm {
    height: 32px;
}
.form-control-xs {
    height: 16px;
}

.form-control-currency-disabled {
    position:relative;
    &:after {
       content: "\2335";
        color: black;
        right: 5px;
        top: -2px;
        height: 20px;
        padding: 11px 0px 0px 12px;
        position: absolute;
        pointer-events: none;
        opacity: 0.2;
    }

}


.form-control-currency {
    width: 45px !important;
    min-width: 45px !important;
    border-radius: 0px !important;
    border-right: none !important;
 

    line-height: 24px !important;
    -webkit-appearance: none;


}



.input-group-text {
    font-size: 14px !important;
    padding-top: 0;
    padding-bottom: 0;
    background-color: $offwhite;
    font-weight: 600;

    &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }
}

.react-tagsinput-input::placeholder,
.form-control::placeholder {
    color: $color-subsidiary-lighter !important;
    opacity: 1;
    font-size: 13px !important;


}

.is-invalid::placeholder {
    color: $color-invalid !important;
}

/*textarea.form-control { height: unset!important; min-height: 40px!important; }*/

.form-row .col, .form-row [class*="col-"] {
    padding-right: 5px;
}




.password-toggle-wrap {
    position: relative;
    margin-right: 30px;




    [type=password],[type=text] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .input-group & {
        width: 100%;
        [type=password],[type=text] {
            width: 100%;
            border-radius: 0;
        }
    }
}

.password-toggle-wrap {
    .password-toggle{
        position: absolute !important;
        top: 0 !important;
        bottom: 0 !important;
        margin-top: 0!important;
        height: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border: 1px solid $color-subsidiary-lighter;
        border-left: none;

        right: -30px !important;
        cursor: pointer;
        color: $color-primary-dark;
        background: $color-subsidiary-lightest;
        transition: all 0.2s ease;

        &:hover {
            background-color: $color-subsidiary-lighter;
            color: $color-primary-dark;
        }


    }
}

/*
  Loading screen
*/
.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
.loading .logo, .loading .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loading .logo img {
    width: 300px;
}
.loading .spinner img {
    width: 100px;
}
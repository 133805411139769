
.fbsidebar {
    background: $white;
    box-shadow: $boxshadow;

    padding: $spacing;

    position: sticky;
    top: -4rem;
    height: 80vh;
    overflow: auto;
}

.fbsidebar-header {
    font-size: 16px;
    text-transform: uppercase;
    color: $color-primary;
    font-weight: 600;
    margin-bottom: $spacing;
}

.fbsidebar-group {
    border: 1px solid $color-primary-lightest;
}

.fbsidebar-groupheader {
    background-color: $color-primary-lightest;
    padding: $spacing-small;

    font-size: 14px;
    text-transform: uppercase;
    color: $color-primary-light;
    font-weight: 600;
}

.fbsidebar-list {

}

.fbsidebar-item {
    padding: $spacing $spacing-small;

    background-color: $white;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    border-top: 1px solid $color-primary-lightest;

    line-height: 1.1;

    > :not(:last-child) {
        margin-right: $spacing-small;
    }
}

.fbsidebar-handle {
    color: $color-primary-light;
}
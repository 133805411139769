
/*
  Authentication
*/
.authentication {
    width: 370px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

/**
    Tags input
*/
.react-tagsinput {
    border: 1px solid $color-subsidiary-lighter!important;
    border-radius: 0.25rem;
    padding-left: 8px !important;
    &.react-tagsinput--focused {
        border: 2px solid $color-primary-light !important;
    }
    * {
        font-family: $font-family !important;
    }

}
.react-tagsinput-input {
    font-size: 16px !important;
    padding: 2px 5px !important;
    color: $color-primary-dark !important;

}
.react-tagsinput .react-tagsinput-tag {
    border-radius: 5px;
    border-color: $color-primary-light;
    background: $color-primary-light;
    color: $white;
    font-size: 14px;
    padding: 0 5px 2px !important;

}
.react-tagsinput .react-tagsinput-tag .react-tagsinput-remove{
    color: $white;
    margin-left: 5px;
    text-decoration: none;
}
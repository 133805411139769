
/**
  Leaflet map
*/
.leaflet-container {
    height: 400px;
    width: 100%;
}

.react-select__multi-value {
    background: $color-primary-light !important;
    color: white;

    .react-select__multi-value__label {
        color: white;
    }
}


/*
  Modal
*/
.modal {
    z-index: 9998 !important;
}
.modal .modal-dialog {
}
.modal .modal-dialog .modal-content {
    border-radius: 4px;
    border: 0;
}
.modal .modal-dialog .modal-content .modal-header {
    position: relative;
    border: 0;
    padding: 30px 50px 0
}
.modal .modal-dialog .modal-content .modal-header .modal-title {
    width: 100%;
    text-align: center;
    font-size: 26px;
    font-weight: normal;
}
.modal .modal-dialog .modal-content .modal-header .close {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 0;
    margin: 0;
    line-height: 28px;
    height: 28px;
    width: 28px;
    border-radius: 100%;
    text-shadow: none!important;
    outline: none!important;
    background: $color-primary;
    display: flex;
    justify-content: center;
    opacity: 1!important;
}
.modal .modal-dialog .modal-content .modal-header .close span {
    font-size: 24px;
    color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.modal .modal-dialog .modal-content .modal-body {
    padding: 30px 50px;
    // max-height: 70vh;
}
.modal.delete-modal .modal-dialog {
}
.modal.delete-modal .modal-dialog .modal-content {
    background: $color-delete-background;
}
.modal.delete-modal .modal-dialog .modal-content .modal-header .close {
    background: $color-invalid-border;
}
.modal.delete-modal .modal-dialog .modal-content .actions {
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: center;
    padding-top: $spacing;

    .btn + .btn {
        margin-right: 5px;
    }
}
.modal.delete-modal .modal-dialog .modal-content .actions .btn {
    padding-left: 25px;
    padding-right: 25px;
}
.modal.delete-modal .modal-dialog .modal-content .actions .btn-danger {
    color: $white;
    background: $color-invalid-border;
    border-color: $color-invalid-border;
    transition: all 0.2ease;

    &:hover {
        background: darken($color-invalid-border, 10%);
        border-color: darken($color-invalid-border, 10%);
    }

    svg {
        color: $white !important;
    }
}
.modal.delete-modal .modal-dialog .modal-content .actions .btn-light {
    color: $color-subsidiary-darker;
    background: $white;
    border-color: $white;
    
     transition: all 0.2ease;
     &:hover {
        background: darken($white, 2%);
        border-color: darken($white, 2%);
    }
}
.modal.delete-modal .modal-dialog .modal-content .actions .btn svg {
    margin-right: 10px;
}

.modal.internal-pages-select-modal .modal-dialog .modal-content {
    height: 450px;
    padding-bottom: 30px;
}
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body {
    overflow-y: auto;
    padding-bottom: 0;
}
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select {
    border-left: 1px solid $offwhite;
    border-right: 1px solid $offwhite;
    border-bottom: 1px solid $offwhite;
}
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item {
    padding: 15px 20px 15px 47px;
    border-top: 1px solid $offwhite;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item .arrow {
    display: none;
    color: $color-primary-dark;
}
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item p {
    margin: 0;
    color: $color-primary-dark;
}
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item.sub-1 {
    background: $offwhite;
    padding-left: calc(60px + 31px);
    position: relative;
}
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item.sub-2 {
    background: $offwhite;
    padding-left: calc(120px + 31px);
    position: relative;
}
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item.sub-1:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    background: $white;
    height: 100%;
}
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item.sub-2:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 120px;
    background: $white;
    height: 100%;
}
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item:hover,
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item:focus {
    cursor: pointer;
    background: $color-primary-lightest;
}
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item:hover .arrow,
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item:focus .arrow {
    display: block;
}
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item.disabled {
    cursor: no-drop;
}
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item.disabled:hover,
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item.disabled:focus {
    background: unset;
}
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item.disabled:hover .arrow,
.modal.internal-pages-select-modal .modal-dialog .modal-content .modal-body .internal-pages-select .internal-pages-select-item.disabled:focus .arrow {
    display: none
}

.modal-dialog-centered.modal-dialog-scrollable {
    height: auto;
}

.modal-dialog-scrollable .modal-content {
    overflow: visible;
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem) !important;
}

.strength-bar {
}
.strength-bar .progress {
    height: 5px;
    background: $color-primary-light;
}
.strength-bar .progress.weak .progress-bar {
    background: #ff0000;
}
.strength-bar .progress.normal .progress-bar {
    background: #ffa000;
}
.strength-bar .progress.strong .progress-bar {
    background: $color-primary
}
.strength-bar p {
    font-size: 13px;
    color: $color-primary-dark;
    margin-top: 4px;
}
.strength-bar p span {
    font-weight: bold;
}
/*
---------------------------------------------------------------------- */

@media (min-width: 1200px) {

}

/*
---------------------------------------------------------------------- */

@media (max-width: 1199.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .top-nav { width: 75%; }
    .left-nav { width: 25%; }
    .cms .content { left: 25%; padding: 55px 25px 30px;}
}

@media (min-width: 991px) {

}

/*
---------------------------------------------------------------------- */

@media (max-width: 991.98px) {
    body { overflow-y: auto;}
    .cms { flex-direction: column; }
    .cms .content { left: 0; padding: 55px 10px 30px; position: relative; order: 2; top: 0; margin: 0 -15px; }

    .cms .content .title, .cms .content .overview-text, .custom-form { padding: 0 10px; width: 100%; }

    .custom-data-table {
        padding: 10px 10px 30px;
    }

    /* Mobile nav */
    .top-nav { width: 100%; order: 1;}
    .top-nav .logo { margin-right: 20px; }
    .top-nav .logo img { max-height: 40px;}
    .top-nav .actions .user::before { margin: 21px 15px 21px 5px }
    .left-nav { width: 0; position: fixed; z-index: 9999999;left: 0; top: 0; overflow:visible; }
    .left-nav .logo,
    .left-nav .website-select,
    .left-nav .language-select,
    .left-nav .left-navigation-wrapper { display: none; overflow: scroll; }
    .top-nav .dropdown-navbar { display: flex; justify-content: space-between; width: 100%; align-items: center }
    .top-nav .dropdown-navbar .react-select-container { width: 240px;}
    .top-nav .actions .user .dropdown button svg { margin: auto 10px auto 15px; }
    .top-nav .actions .user .dropdown button img { display: none }

    .left-nav.mobile-open { width: 260px;}
    .left-nav.mobile-open .logo,
    .left-nav.mobile-open .website-select,
    .left-nav.mobile-open .language-select,
    .left-nav.mobile-open .left-navigation-wrapper { display: block; }

    .left-nav .responsive-button-wrapper { background: #15252F; border-top-right-radius: 12px; border-bottom-right-radius: 12px; display: block; position: absolute; padding: 10px 14px; top: 9.5%;}
    .left-nav .responsive-button-wrapper .responsive-button { width: 20px; display: block; z-index: 99999999;}
    .left-nav .responsive-button-wrapper .responsive-button:before,
    .left-nav .responsive-button-wrapper .responsive-button:after,
    .left-nav .responsive-button-wrapper .responsive-button span { background: #fff; content: ''; display: block; height: 3px; margin: 4px 0; }
    .left-nav.mobile-open .responsive-button-wrapper { left: 260px; }
    .left-nav.mobile-open .responsive-button-wrapper .responsive-button:before {  transform: translateY(7px) rotate(135deg); }
    .left-nav.mobile-open .responsive-button-wrapper .responsive-button:after { transform: translateY(-7px) rotate(-135deg); }
    .left-nav.mobile-open .responsive-button-wrapper .responsive-button span { transform: scale(0); }


}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 768px) {

}

/*
---------------------------------------------------------------------- */

@media (max-width: 767.98px) {

}

@media (min-width: 576px) and (max-width: 767.98px) {

}

@media (min-width: 576px) {

}

/*
---------------------------------------------------------------------- */

@media (max-width: 575.98px) {
    .top-nav { width: 100%; order: 1; display: flex; flex-direction: row; height: auto;}
    .topnav-module-select {
        display: none;
    }
    
    .lang-switch {
        display: none;
    }
    
    .top-nav .actions .user::before {
        display: none;
    }
    
    .cms .content .title {
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 10px;
        
    }
    
    .cms .content .title h1 {
        width: 100%;
            margin-bottom: 10px;
        }
    
}
$green:             #00966e;
$green-light:       #7AA398;
$green-lighter:     #E7F3D6;
$green-lightest:    #ECF5F1;
$green-dark:        #2F554B;

$test: #86C132;
$test2: #dc3545;

$color-primary:             $green;
$color-primary-light:       $green-light;
$color-primary-lighter:     $green-lighter;
$color-primary-lightest:    $green-lightest;
$color-primary-dark:        $green-dark;

$white: #ffffff;
$offwhite: #F6F6F6;
$gray-lightest: #E8E9EC;
$gray-lighter: #ced4da;
$gray-light: #97a2ac;
$gray: #6c757d;
$gray-dark: #4a4f53;
$gray-darker:  #15252F;
$gray-darkest:  #0D1923;
$black: #000000;

$color-subsidiary-lightest:     $gray-lightest;
$color-subsidiary-lighter:      $gray-lighter;
$color-subsidiary-light:      $gray-light;
$color-subsidiary:              $gray;
$color-subsidiary-dark:         $gray-dark;
$color-subsidiary-darker:       $gray-darker;
$color-subsidiary-darkest:      $gray-darkest;

$color-active:              $green;
$color-active-background:   $green-lighter;
$color-active-border:       $green;

$color-inactive:            #FFCA83;
$color-inactive-background: #FFF4E5;
$color-inactive-border:     #FFCA83;

$color-edit:                #208DD1;
$color-edit-background:     #D2E8F6;
$color-edit-border:         #208DD1;

$color-delete:              #FF7285;
$color-delete-background:   #FFE2E6;
$color-delete-border:       #FF7285;

$color-border: $offwhite;

$color-invalid:             $color-delete;
$color-invalid-background:  $color-delete-background;
$color-invalid-border:      $color-delete-border;

$color-valid:             #86C132;
$color-valid-background:  #e0ecce;
$color-valid-border:      #86C132;

$spacing:           10px;
$spacing-large:     20px;
$spacing-larger:    30px;
$spacing-small:     6px;

$color-text-light: $color-subsidiary-lighter;

$font-size-small: 13px;
$font-family: 'Source Sans Pro', sans-serif;

$boxshadow: 0px 2px 6px 0px rgba(0,0,0,0.08);
$boxshadow-active: 0 0 6px 2px rgba($black, 0.16);

$breakpoints: (
    'xs': 0,
    'sm': 420px,
    'md': 769px,
    'lg': 960px,
    'xl': 1200px,
    'xxl': 1440px
);
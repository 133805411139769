.languages {

    display: flex;

    .language {
        background: $color-primary-lightest;
        height: 23px;
        padding: 0 7px;
        border-radius: 5px;
        margin: 0 5px;

        p {
            color: $color-primary-dark;
            font-weight: 600;
            display: flex;
            align-items: center;
            margin: 0;
            text-transform: uppercase;

            a {
                font-size: 11px;
                color: $color-primary-light;
                margin-left: $spacing-small;
                text-decoration: none;
                &:hover {
                    color: $color-primary-dark;
                }
            }
        }
    }
    .language-add {
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $offwhite;
            height: 23px;
            padding: 0 7px;
            border-radius: 5px;
            font-size: 11px;
            text-decoration: none;
            color: $color-subsidiary-lighter;

            transition: 0.2s;
            &:hover {
                background: $color-primary;
                color: $white;
            }
        }
    }
}

.lang-switch {
    a {
        &.active {
            background: transparent !important;
            border: none !important;
            &:hover {
                background: transparent !important;
            }
        }


    }
}

/*
  Lang Switch
*/
.lang-switch {
}
.lang-switch ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}
.lang-switch ul li {
    margin: 10px;
}
.lang-switch ul li a {
    text-transform: uppercase;
    font-size: 15px;
    color: $color-subsidiary-darker;
    font-weight: lighter;
}
.lang-switch ul li a.active {
    font-weight: 600;
}

